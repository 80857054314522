import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area } from 'recharts';

const EvolutionCA = ({ data }) => {
  // Map of month names to their respective indices
  const monthToIndex = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
  };

  // Format and sort the data
  const formattedData = data.map(item => {
    const [monthName, year] = item.month.split('-');
    
    // Parse the year and month index correctly
    const monthIndex = monthToIndex[monthName.toLowerCase()];
    const parsedYear = parseInt(year) || 0; // Handle the case where year is NaN
    
    return {
      ...item,
      displayMonth: monthName.charAt(0).toUpperCase() + monthName.slice(1), // Capitalize the month name
      monthIndex,
      year: parsedYear
    };
  }).sort((a, b) => (a.year - b.year) || (a.monthIndex - b.monthIndex)); // Sort by year first, then by month
  
  

  return (
    <div className='container-statistique-statut'>
      <h2 className='title-statiquestatut'>Évolution du Chiffre d'Affaires</h2>
      <div className="chiffres-cles">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="displayMonth" />
            <YAxis label={{ value: 'CA (€)', angle: -90, position: 'insideLeft' }} />
            <Tooltip formatter={(value) => `${value.toFixed(2)} €`} />
            <Line type="monotone" dataKey="ca" stroke="#FBC8C5" strokeWidth={2} activeDot={{ r: 8 }} />
            <Area type="monotone" dataKey="ca" fill="#FBC8C5" fillOpacity={0.9} strokeWidth={8} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default EvolutionCA;
