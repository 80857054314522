import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faTable,
  faCode,
  faClipboardList,
  faChartBar ,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo.png';
import './BarreNavigation.css';

function BarreNavigation() {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  // Récupérez l'information sur le fait que l'utilisateur soit admin ou non
  const isAdminStored = localStorage.getItem('is_admin');
  const isAdmin = isAdminStored ? JSON.parse(isAdminStored) : false;

  const allowedAdminIds = ['69', '383','73','416'];
  const currentUserId = localStorage.getItem('userId');

  const canAccessStats = allowedAdminIds.includes(currentUserId);

  

  return (
    <nav className="sidebar">
      <div className="sidebar-header">
        <img src={logo} alt="Logo" />
        <h2>Application de facturation</h2>
      </div>

      <div className="sidebar-menu">
        {/* Affiche les options d'administration uniquement si l'utilisateur est administrateur */}
        {isAdmin  && (
          <>
            <p className="menu-section">ESPACE ADMIN</p>
            <Link to="/companies" className={`menu-item ${isActive("/companies") ? "active" : ""}`}>
              <FontAwesomeIcon icon={faChartLine} /> Gestion du portefeuille
            </Link>
            <Link to="/users" className={`menu-item ${isActive("/users") ? "active" : ""}`}>
              <FontAwesomeIcon icon={faClipboardList} /> Gestion des habilitations
            </Link>
            <Link to="/gestionfacturation" className={`menu-item ${isActive("/gestionfacturation") ? "active" : ""}`}>
              <FontAwesomeIcon icon={faTable} /> Gestion des facturations
            </Link>
           
            {canAccessStats && (
              <Link to="/statistiquespage" className={`menu-item ${isActive("/statistiquespage") ? "active" : ""}`}>
                <FontAwesomeIcon icon={faChartBar} /> Statistiques
              </Link>
            )}
          </>
        )}
{(!isAdmin || isAdmin) && (
  <>
        {/* Cette partie est affichée pour tous les utilisateurs */}
        <p className="menu-section">ESPACE GESTIONNAIRE</p>
        <Link to="/facturation" className={`menu-item ${isActive("/facturation") ? "active" : ""}`}>
          <FontAwesomeIcon icon={faCode} /> Facturation
        </Link>
      
        </>
)}
      </div>
      
    </nav>
  );
  
}

export default BarreNavigation;
