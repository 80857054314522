import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import CompanyList from "./pages/CompanyList/CompanyList";
import PrivateRoute from "./components/PrivateRoute";
import AuthRoute from "./components/AuthRoute"; // Assurez-vous que le chemin d'importation est correct
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UsersList from "./pages/User/UsersList";
import Facturation from "./pages/Facturation/Facturation";
import GestionFacturation from "./pages/GestionFacturation/GestionFacturation";
import LoginPage from "./pages/LoginPage/LoginPage";
import "./App.css";

import StatistiquesPage from "./pages/StatiquesPage/StatiquesPage";


function App() {
  // Liste des IDs des administrateurs autorisés pour la page statistiques
  const allowedAdminIds = ['69','383','73','416']; // Remplacez avec les IDs réels des administrateurs autorisés
  return (
    <Router>
      <div>
        <ToastContainer className="my-toast-container" />
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/facturation"
            element={
              <AuthRoute>
                <Facturation />
              </AuthRoute>
            }
          />
          
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <UsersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/companies"
            element={
              <PrivateRoute>
                <CompanyList />
              </PrivateRoute>
            }
          />
          <Route
            path="/gestionfacturation"
            element={
              <PrivateRoute>
                <GestionFacturation />
              </PrivateRoute>
            }
          />
        
          <Route path="/statistiquespage" 
          element={
            <PrivateRoute allowedAdmins={allowedAdminIds}>
              <StatistiquesPage/>
            </PrivateRoute>
            } />
          {/* Vous pouvez ajouter d'autres routes ici */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
