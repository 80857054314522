import React from 'react';
import Modal from 'react-modal';
import "./confirmModal.css"

const ConfirmModal = ({ isOpen, onRequestClose, currentFacturation, performSendEmail }) => {
  const date = new Date(currentFacturation.date_envoi_mail);
const isValidDate = !isNaN(date.getTime());
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmation d'envoi"
      ariaHideApp={false}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <h2 className='title_modal'>Confirmation d'envoi</h2>
      <p>Un email a déjà été envoyé pour cette facturation le {isValidDate ? date.toLocaleDateString("fr-FR") : "date inconnue"}.</p>
      <p className='qst'>Voulez-vous envoyer à nouveau ?</p>
      <div className='container_btn'>
      <button className='btn_confirmModal' onClick={onRequestClose}>Non</button>
      <button  className='btn_confirmModal' onClick={() => {
        performSendEmail(currentFacturation.siret, currentFacturation.mois_facturation);
        
        onRequestClose();
        console.log("Date envoyée:", currentFacturation.date_envoi_mail);
      }}>Oui, envoyer à nouveau</button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
