import React from "react";
import "./ModalStyle.css";

function Modal({ isOpen,children, title }) {
  if (!isOpen) return null;


  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="title">{title}</h2>
        </div>

        <div className="modal-body">
          {children} {/* Affiche les enfants du composant */}
        </div>
        
      </div>
    </div>
  );
}

export default Modal;
