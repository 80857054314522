import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Badge, Dropdown } from "react-bootstrap";
import { FaPaperPlane } from "react-icons/fa";
import API_BASE_URL from "../../API/ConfigAPI";
import { toast } from "react-toastify";

import BarreNavigation from "../../components/BarreNavigation/BarreNavigation";
import Navbarhorizontal from "../../components/Navbarhorizontal/Navbarhorizontal";
import "./gestionfacturation.css";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

function GestionFacturation() {
  const [facturations, setFacturations] = useState([]);
  /* eslint-disable no-unused-vars */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [prevMonths, setPrevMonths] = useState([]);
  const [modalMessage, setModalMessage] = useState("");

  const [selectedGestionnaires, setSelectedGestionnaires] = useState([]);
  const [availableGestionnaires, setAvailableGestionnaires] = useState([]);
  const [searchRaisonSociale, setSearchRaisonSociale] = useState(""); // Pour la recherche par raison sociale

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentFacturation, setCurrentFacturation] = useState({
    siret: "",
    mois_facturation: "",
  });
  const [selectedStatut, setSelectedStatut] = useState(null);

  // gestion du filtre du facturation par mois
  // Formatteur de date pour correspondre au format utilisé par vos facturations
  const monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  // Convertit une date en format "mois-année", par exemple "mars-2024"
  const formatMonth = (date) => {
    return `${monthNames[date.getMonth()]}-${date.getFullYear()}`;
  };

  const [selectedMonth, setSelectedMonth] = useState(formatMonth(new Date())); // mettez par défaut le mois en cours

  // Convertit un mois de format "mois-année" en Date
  const monthToDate = (monthYear) => {
    const [monthName, year] = monthYear.split("-");
    const monthIndex = monthNames.indexOf(monthName.toLowerCase());
    return new Date(year, monthIndex);
  };

  // Fonction pour filtrer par mois sélectionné
  const filterBySelectedMonth = (facturation) => {
    const facturationDate = monthToDate(facturation.mois_facturation);
    const selectedDate = monthToDate(selectedMonth);
    return (
      facturationDate.getMonth() === selectedDate.getMonth() &&
      facturationDate.getFullYear() === selectedDate.getFullYear()
    );
  };

  const facturationsBySelectedMonth = facturations.filter(
    filterBySelectedMonth
  );

  const facturationsBySelectedMonthAndStatut = facturations
    .filter(filterBySelectedMonth)
    .filter((facturation) =>
      selectedStatut ? facturation.statut === selectedStatut : true
    );

  useEffect(() => {
    const today = new Date();
    const currentMonth = formatMonth(today);
    setActiveTabs([currentMonth]);

    // Calculer les mois précédents
    const prevMonths = [];
    for (let i = 1; i <= 2; i++) {
      const prevMonth = new Date(today.getFullYear(), today.getMonth() - i, 1);
      prevMonths.push(formatMonth(prevMonth));
    }
    setPrevMonths(prevMonths);
    // eslint-disable-next-line
  }, []);

  // Créez un état pour stocker uniquement les mois ayant des facturations
  const [activeTabs, setActiveTabs] = useState([]);

  // Mettez à jour les onglets actifs en fonction des facturations
  useEffect(() => {
    const uniqueMonths = new Set(
      facturations.map((fact) => fact.mois_facturation)
    );
    setActiveTabs(Array.from(uniqueMonths));
  }, [facturations]);
  // fin

  // Calculer le total de chaque statut uniquement pour le mois sélectionné
  const totalParStatutMoisSelectionne = facturationsBySelectedMonth.reduce(
    (acc, facturation) => {
      const { statut } = facturation;
      acc[statut] = (acc[statut] || 0) + 1;
      return acc;
    },
    {}
  );

  // Définition de la fonction openModal
  const openModal = (title, message) => {
    setIsModalOpen(true);
    setErrorMessage(message);
  };
  useEffect(() => {
    const uniqueGestionnaireNoms = [
      ...new Set(
        facturations.map((facturation) => facturation.gestionnaire_nom)
      ),
    ];
    const options = uniqueGestionnaireNoms.map((nom) => ({
      label: nom,
      value: nom,
    }));
    setAvailableGestionnaires(options); // Supposons que cet état est déjà déclaré
  }, [facturations]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedGestionnaires(selectedOptions);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${API_BASE_URL}/home/api/facturations/?page=gestion_facturation`, {
        headers: {
          Authorization: `Token ${token}`, // Utilisez le token dans l'en-tête Authorization
        },
      })
      .then((response) => {
        // Filtrer par statut "Envoyer pour validation"
        const filteredData = response.data.filter(
          (facturation) => facturation.statut !== "A faire"
        );
        setFacturations(filteredData);
      })
      .catch((error) =>
        toast.error("Erreur lors de la récupération des données:", error)
      );
    const table = document.querySelector("table");
    const rowCount = table.getElementsByTagName("tr").length;

    // Définir un seuil pour activer la barre de défilement
    const seuil = 14;

    if (rowCount > seuil) {
      table.style.maxHeight = "500px"; // Définir une hauteur maximale pour la table
      table.style.overflowY = "auto"; // Activer la barre de défilement
    }
  }, []);

  const changeStatus = (id, newStatus) => {
    const token = localStorage.getItem("token");
    // Trouver la facturation correspondante par ID
    const facturationToUpdate = facturations.find(
      (facturation) => facturation.id === id
    );
    if (!facturationToUpdate) {
      toast.error("Facturation non trouvée");
      return;
    }

    // Mise à jour du statut dans l'objet de facturation
    const updatedFacturation = { ...facturationToUpdate, statut: newStatus };

    axios
      .put(`${API_BASE_URL}/home/api/facturations/${id}/`, updatedFacturation, {
        headers: {
          Authorization: `Token ${token}`, // Utilisez le token dans l'en-tête Authorization
        },
      })
      .then((response) => {
        // Mise à jour de l'état local après la mise à jour réussie
        const updatedFacturations = facturations.map((facturation) => {
          if (facturation.id === id) {
            return updatedFacturation;
          }
          return facturation;
        });
        setFacturations(updatedFacturations);
      })
      .catch((error) =>
        toast.error("Erreur lors de la mise à jour du statut", error)
      );
  };

  const generateFacture = (id) => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `${API_BASE_URL}/home/api/generate-facturations/`,
        { id },
        {
          headers: {
            Authorization: `Token ${token}`, // Utilisez le token dans l'en-tête Authorization
          },
        }
      )
      .then((response) => {
        // Traitez ici la réponse de l'API après une génération réussie
        console.log("Facture générée avec succès", response.data);
        toast.success("Facture générée avec succès", response.data);
        // Vous pouvez actualiser les données de la facturation ici si nécessaire
      })
      .catch((error) => {
        console.error("Erreur lors de la génération de la facture", error);
      });
  };

  // Fonction pour Exporter koala complet et koala partiel
  const generateExcel = (moisFacturation, type) => {
    const token = localStorage.getItem("token");

    axios
      .get(
        `${API_BASE_URL}/home/api/export_excel/${moisFacturation}/${type}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 204) {
          toast.info("Aucune nouvelle facturation à exporter.");
        } else if (
          response.data.message ===
          "Aucune facturation n'est finalisée pour le mois indiqué"
        ) {
          toast.info(
            "Aucune facturation n'est finalisée pour le mois indiqué."
          );
        } else if (
          response.headers["content-type"] ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          // Vérifier et afficher le contentDisposition
          const contentDisposition = response.headers["content-disposition"];
          console.log("Content-Disposition header:", contentDisposition);

          let fileName = "export.xlsx"; // Valeur par défaut
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch.length > 1) {
              fileName = fileNameMatch[1];
            }
          }

          console.log("Nom du fichier:", fileName);

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          throw new Error("La réponse du serveur n'est pas un fichier Excel.");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la génération du fichier Excel:", error);
        if (error.response) {
          if (error.response.status === 404) {
            toast.error(
              "Aucune facturation n'est finalisée pour le mois indiqué."
            );
          } else {
            toast.error(
              error.response.data.detail || "Une erreur s'est produite."
            );
          }
        } else {
          toast.error(
            "Une erreur s'est produite lors de la génération du fichier Excel. Veuillez réessayer."
          );
        }
      });
  };

  const handleExport = (type) => {
    // Afficher la boîte de dialogue pour saisir le mois et l'année
    let moisFacturation = prompt(
      "Veuillez saisir le mois et l'année au format 'mois-année' (par exemple, 'mars-2024') :"
    );

    const moisRegex =
      /^(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)-\d{4}$/;

    while (
      moisFacturation !== null &&
      !moisRegex.test(moisFacturation.trim())
    ) {
      moisFacturation = prompt(
        "Le format du mois de facturation est invalide. \nUtilisez le format 'mois-année' (par exemple:'mars-2024'). \nVeuillez saisir à nouveau :"
      );
    }

    if (moisFacturation !== null) {
      // Appeler la fonction appropriée selon le type choisi
      generateExcel(moisFacturation, type);
    }
  };

  // Fonction pour afficher la boîte de dialogue pour choisir le mois et l'année
  const demanderMoisAnnee = () => {
    let moisFacturation = prompt(
      "Veuillez saisir le mois et l'année au format 'mois-année' (par exemple, 'mars-2024') :"
    );

    const moisRegex =
      /^(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)-\d{4}$/;

    while (
      moisFacturation !== null &&
      !moisRegex.test(moisFacturation.trim())
    ) {
      moisFacturation = prompt(
        "Le format du mois de facturation est invalide. \nUtilisez le format 'mois-année' (par exemple:'mars-2024'). \nVeuillez saisir à nouveau :"
      );
    }

    if (moisFacturation !== null) {
      generateExcel(moisFacturation);
    }
  };

  // Appeler la fonction 'demanderMoisAnnee' lorsque l'utilisateur clique sur le bouton
  useEffect(() => {
    // Ajoutez le gestionnaire d'événements dans useEffect pour garantir que le composant a été rendu
    const boutonGenerateExcel = document.getElementById(
      "bouton-generate-excel"
    );
    if (boutonGenerateExcel) {
      boutonGenerateExcel.addEventListener("click", demanderMoisAnnee);
    }

    return () => {
      // Nettoyez le gestionnaire d'événements lors du démontage du composant
      if (boutonGenerateExcel) {
        boutonGenerateExcel.removeEventListener("click", demanderMoisAnnee);
      }
    };
    // eslint-disable-next-line
  }, []);

  const handleGeneratePDF = (siret, moisFacturation, statut) => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${API_BASE_URL}/home/api/generate-facture/${siret}/${moisFacturation}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          responseType: "blob", // Indique que la réponse est un Blob (pour les fichiers)
        }
      )
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Affichage de la boîte de dialogue de confirmation
        if (window.confirm("Voulez-vous télécharger la facture PDF ?")) {
          // Téléchargement du PDF
          const a = document.createElement("a");
          a.href = pdfUrl;
          a.download = `facture_${siret}_${moisFacturation}.pdf`;
          a.click();
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la génération du PDF", error);
      });
  };

  const handleStatusChange = (facturationId, event) => {
    const newStatus = event.target.value;
    if (
      newStatus === "Terminé" &&
      !window.confirm("Confirmez-vous le changement de statut en 'Terminé' ?")
    ) {
      // Si l'utilisateur ne confirme pas, n'effectuez pas le changement de statut
      return;
    }
    // Changez le statut si l'utilisateur confirme, ou s'il s'agit d'autres statuts
    changeStatus(facturationId, newStatus);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 500, // Ajustez la largeur à votre convenance
      // Vous pouvez ajouter d'autres styles ici
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 500, // Assurez-vous que la largeur du menu déroulant correspond à celle du contrôle
      // Vous pouvez ajouter d'autres styles ici
    }),
    // Vous pouvez personnaliser d'autres parties du Select ici (options, multiValue, etc.)
  };
  function getBadgeVariant(statut) {
    switch (statut) {
      case "A revoir":
        return "warning";
      case "En revue":
        return "primary";
      case "Terminé":
        return "success";
      default:
        return "secondary";
    }
  }
  //  function envoi email
  const sendFactureEmail = (siret, mois_facturation) => {
    const facturation = facturations.find(
      (fact) =>
        fact.siret === siret && fact.mois_facturation === mois_facturation
    );
    if (facturation.date_envoi_mail) {
      const formattedDate = new Date(
        facturation.date_envoi_mail
      ).toLocaleDateString("fr-FR");
      setCurrentFacturation({ ...facturation });
      setModalMessage(
        `Un email a déjà été envoyé le ${formattedDate}. Voulez-vous envoyer à nouveau ?`
      );
      setShowConfirmModal(true);
    } else {
      performSendEmail(siret, mois_facturation);
    }
  };

  const performSendEmail = (siret, mois_facturation) => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${API_BASE_URL}/home/api/envoyer_facture/${siret}/${mois_facturation}/`,
        {
          siret,
          mois_facturation,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Email envoyé avec succès", response.data);
        const dateEnvoi = new Date(
          response.data.date_envoi_mail
        ).toLocaleDateString("fr-FR"); // Adaptez au format de date que vous recevez si nécessaire
        toast.success(`Email envoyé avec succès le ${dateEnvoi}!`);
        // Mettre à jour l'état pour indiquer que l'email a été envoyé
        const updatedFacturations = facturations.map((fact) =>
          fact.siret === siret && fact.mois_facturation === mois_facturation
            ? {
                ...fact,
                emailSent: true,
                date_envoi_mail: response.data.date_envoi_mail,
              }
            : fact
        );
        setFacturations(updatedFacturations);
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi de l'email", error);
        alert("Erreur lors de l'envoi de l'email.");
      });
  };
  // fonction rendre les facture du status En revue au status Terminé
  const handleMarkAllAsTerminated = () => {
    const token = localStorage.getItem("token");

    // Filtrer les facturations qui ont le statut "En revue" et qui sont du mois sélectionné
    const facturationsEnRevue = facturations.filter(
      (facturation) =>
        facturation.statut === "En revue" &&
        facturation.mois_facturation === selectedMonth // Filtrer par le mois sélectionné
    );

    if (facturationsEnRevue.length === 0) {
      toast.info(
        `Aucune facturation en statut 'En revue' pour ${selectedMonth}.`
      );
      return;
    }

    // Mettre à jour chaque facturation
    const promises = facturationsEnRevue.map((facturation) => {
      const updatedFacturation = { ...facturation, statut: "Terminé" };
      return axios.put(
        `${API_BASE_URL}/home/api/facturations/${facturation.id}/`,
        updatedFacturation,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
    });

    // Exécuter toutes les requêtes en parallèle
    Promise.all(promises)
      .then(() => {
        // Mettre à jour l'état local après toutes les mises à jour réussies
        const updatedFacturations = facturations.map((facturation) => {
          if (
            facturation.statut === "En revue" &&
            facturation.mois_facturation === selectedMonth
          ) {
            return { ...facturation, statut: "Terminé" };
          }
          return facturation;
        });

        setFacturations(updatedFacturations);
        toast.success(
          `Toutes les facturations en revue pour ${selectedMonth} ont été passées au statut "Terminé".`
        );
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour des facturations", error);
        toast.error(
          "Une erreur est survenue lors de la mise à jour des facturations."
        );
      });
  };
  const handleMarkAllAsTerminatedClick = () => {
    const confirmed = window.confirm(`Êtes-vous sûr de vouloir terminer toutes les factures 'En revue' pour le mois ${selectedMonth}?`);
    if (confirmed) {
        handleMarkAllAsTerminated();
    }
};

  // afficher par defaut le mois en cour
  useEffect(() => {
    const fetchFacturations = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${API_BASE_URL}/home/api/facturations/`,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        const facturations = response.data.filter(
          (fact) => fact.statut !== "A faire"
        );
        setFacturations(facturations);

        // Mise à jour des mois actifs
        const uniqueMonths = new Set(
          facturations.map((fact) => fact.mois_facturation)
        );
        setActiveTabs(Array.from(uniqueMonths));

        // Déterminez le mois en cours et le mois précédent
        const currentMonth = formatMonth(new Date());
        const lastMonth = formatMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        );

        // Vérifiez si le mois en cours ou le mois précédent a des facturations
        if (uniqueMonths.has(currentMonth)) {
          setSelectedMonth(currentMonth);
        } else if (uniqueMonths.has(lastMonth)) {
          setSelectedMonth(lastMonth);
        } else {
          setSelectedMonth(
            uniqueMonths.size > 0
              ? Array.from(uniqueMonths)[0]
              : formatMonth(new Date())
          );
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    };

    fetchFacturations();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="parent-layout">
      <BarreNavigation />

      <div className="content">
        <div>
          <Navbarhorizontal />
        </div>
        <ConfirmModal
          isOpen={showConfirmModal}
          onRequestClose={() => setShowConfirmModal(false)}
          currentFacturation={currentFacturation}
          performSendEmail={performSendEmail}
        />
        <div className="container">
          <h1>Déclaration des prestations</h1>

          <div className="banner">
            <div className="search-container-gestionfac ">
              <div className="imput-search-container">
                <input
                  type="text"
                  placeholder="Recherche par Raison Sociale ou SIRET..."
                  value={searchRaisonSociale}
                  onChange={(e) => setSearchRaisonSociale(e.target.value)}
                  className="search-input-gestionfac"
                />
              </div>
              
            </div>
            <div className="btn-container">
              <button
                className="btn-generer"
                onClick={handleMarkAllAsTerminatedClick}
              >
                Terminer les factures "En revue"
              </button>
              <button className="btn-generer" onClick={() => generateFacture()}>
                Générer la facturation
              </button>
              {/* <button
                className="btn-generer"
                onClick={demanderMoisAnnee} // Ajouter directement le gestionnaire d'événements onClick
               >
                Exporter le fichier COALA
              </button>*/}
              <Dropdown>
                <Dropdown.Toggle
                  className="btn-exporter"
                  variant="success"
                  id="dropdown-basic"
                >
                  Exporter
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleExport("Oui")}>
                    Export koala complet
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleExport("Non")}>
                    Export koala partiel
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="container-filtres-gestionnaires">
          <Select
              className="filtres-gestionnaires"
                options={availableGestionnaires}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={handleSelectChange}
                allowSelectAll={true}
                value={selectedGestionnaires}
                //styles={customStyles} // Assurez-vous que cet objet de style est bien défini
                placeholder="Filtrer les gestionnaires..."
              />
         <div className="statut-badges">
  <Badge
    key="all"
    bg={selectedStatut === null ? "dark" : "secondary"}
    className={`me-2 ${selectedStatut === null ? 'active' : ''}`}
    onClick={() => setSelectedStatut(null)}
    style={{ cursor: "pointer", opacity: selectedStatut === null ? 1 : 0.6 }}
  >
    Tous
  </Badge>
  {Object.entries(totalParStatutMoisSelectionne).map(
    ([statut, count], index) => (
      <Badge
        key={statut}
        bg={getBadgeVariant(statut)}
        className={`me-2 ${selectedStatut === statut ? 'active' : ''}`}
        onClick={() => setSelectedStatut(statut === selectedStatut ? null : statut)}
        style={{ cursor: "pointer", opacity: selectedStatut === statut ? 1 : 0.6 }}
      >
        {`${statut} (${count})`}
      </Badge>
    )
  )}
</div>

</div>
          {activeTabs.length > 0 && ( // Affichez les onglets uniquement s'il y a des facturations
            <div className="tabs">
              {activeTabs.map((tab) => (
                <div
                  key={tab}
                  className={`tab ${tab === selectedMonth ? "selected" : ""}`}
                  onClick={() => setSelectedMonth(tab)}
                >
                  {tab}
                </div>
              ))}
              {/* Logique pour le bouton "+" ici si nécessaire */}
            </div>
          )}
          <div className="table-container">
            <table className="gestionfacturation_table">
              <thead>
                <tr>
                  <th>Raison Sociale</th>
                  <th>Gestionnaire</th>
                  <th>Bulletin</th>
                  <th>STC</th>
                  <th>Maladie</th>
                  <th>RC</th>
                  <th>Contrat</th>
                  <th>Licenciement</th>
                  <th>Mission excep</th>
                  <th>Avenant</th>
                  <th>Teulade</th>
                  <th>Accès SILAE</th>
                  <th>Statut</th>
                  <th>Facture PDF</th>
                  <th>Envoi Email</th>
                </tr>
              </thead>
              <tbody>
                {facturations
                  .filter(
                    (facturation) =>
                      (selectedGestionnaires.length === 0 ||
                        selectedGestionnaires
                          .map((option) => option.value)
                          .includes(facturation.gestionnaire_nom)) &&
                      (searchRaisonSociale === "" ||
                        facturation.raison_sociale
                          .toLowerCase()
                          .includes(searchRaisonSociale.toLowerCase()) ||
                        facturation.siret.includes(searchRaisonSociale))
                  )
                  .filter(filterBySelectedMonth)
                  .filter((facturation) =>
                    selectedStatut
                      ? facturation.statut === selectedStatut
                      : true
                  )
                  .map((facturation, index) => (
                    <tr key={facturation.id || index}>
                      <td>
                        <div>{facturation.raison_sociale}</div>
                        <div>{facturation.siret}</div>
                      </td>
                      <td>{facturation.gestionnaire_nom}</td>
                      <td>{facturation.total_bulletin}</td>
                      <td>{facturation.total_stc}</td>
                      <td>{facturation.total_maladie}</td>
                      <td>{facturation.total_RC}</td>
                      <td>{facturation.total_contrat}</td>
                      <td>{facturation.total_licenciement}</td>
                      <td>{facturation.total_mission_excep}</td>
                      <td>{facturation.total_avenant}</td>
                      <td>{facturation.total_teulade}</td>
                      <td>{facturation.total_acces_silae}</td>
                      <td>
                        <select
                          value={facturation.statut}
                          onChange={(event) =>
                            handleStatusChange(facturation.id, event)
                          }
                          className={`select-status ${facturation.statut}`}
                          disabled={facturation.statut === "Terminé"}
                        >
                          <option value="En revue">En revue</option>
                          <option value="A revoir">A revoir</option>
                          <option value="Terminé">Terminé</option>
                        </select>
                      </td>
                      <td>
                        <button
                          className="btn-telecharger"
                          onClick={() =>
                            handleGeneratePDF(
                              facturation.siret,
                              facturation.mois_facturation,
                              facturation.statut
                            )
                          }
                          disabled={facturation.statut !== "Terminé"} // Désactiver si le statut est "Terminé"
                        >
                          <FontAwesomeIcon icon={faDownload} size="lg" />
                        </button>
                      </td>
                      <td>
                      <button
                            className="btn_envoimail"
                            onClick={() =>
                              sendFactureEmail(facturation.siret, facturation.mois_facturation)
                            }
                            disabled={facturation.statut !== "Terminé"}
                            style={{
                              opacity: facturation.date_envoi_mail ? 1 : 0.4, // Opacité conditionnelle
                              cursor: facturation.statut !== "Terminé" ? "not-allowed" : "pointer", // Ajuste le curseur aussi
                            }}
                          >
                            <FaPaperPlane className="iconmail" size={18} />
                          </button>

                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GestionFacturation;
