
import React, { useState, useEffect } from 'react';

import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StatistiqueCAGestionnaire = ({ facturations, selectedMonth, onDataCalculate }) => {
    const [data, setData] = useState([]);
    const [totalCA, setTotalCA] = useState(0);


    useEffect(() => {
        const sumByGestionnaire = {};
        let totalCA = 0;
        let totalFactures = 0;
    
        // Filter facturations by the selected month and "Terminé" status
        const filteredData = facturations.filter(fact => 
            fact.mois_facturation === selectedMonth && fact.statut === "Terminé"
        );
    
        filteredData.forEach(fact => {
            const totalFacture = (fact.total_ttc)
         
            
            if (!isNaN(totalFacture)) {
                totalCA += totalFacture;
                if (sumByGestionnaire[fact.gestionnaire_nom]) {
                    sumByGestionnaire[fact.gestionnaire_nom] += totalFacture;
                } else {
                    sumByGestionnaire[fact.gestionnaire_nom] = totalFacture;
                }
            }
        });
    
        totalFactures = filteredData.length;
    
        const chartData = Object.keys(sumByGestionnaire).map(gest => {
            const firstName = gest.split(' ')[0];
            return {
                name: firstName,
                value: parseFloat(sumByGestionnaire[gest].toFixed(2)),
                nbFactures: totalFactures
            };
        });
    
        setData(chartData);
        setTotalCA(totalCA.toFixed(2));
        onDataCalculate({
            totalCA,
            nbFactures: totalFactures,
            topGestionnaire: Object.keys(sumByGestionnaire).reduce((a, b) => sumByGestionnaire[a] > sumByGestionnaire[b] ? a : b, "")
        });
    
    }, [facturations, selectedMonth, onDataCalculate]);
    
    
    

    const COLORS = ['#FCC712','#6DB492', '#BEE4D0','#58B9D9', '#D9D9D9',  '#F36151', '#FBC8C5','#D9D9D9'];

    return (
        <div className='container-statistique-statut'>
            <h2 className='title-statiquestatut'>Chiffre d'Affaires par Gestionnaire</h2>
            <ResponsiveContainer width="100%" height={320}>
                <PieChart>
                    <Pie 
                        data={data} 
                        cx="50%" 
                        cy="50%" 
                        outerRadius={90} 
                        fill="#8884d8" 
                        dataKey="value" 
                        label={({ name, value }) => `${value.toFixed(2)}€`}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip formatter={(value) => `${value.toFixed(2)}€`}/>
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
            
            <strong style={{ display: 'none' }} > Total CA: {totalCA} €</strong>
                    </div> 
        
    );
};

export default StatistiqueCAGestionnaire;
