import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "../../components/Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import BarreNavigation from "../../components/BarreNavigation/BarreNavigation";
import Navbarhorizontal from "../../components/Navbarhorizontal/Navbarhorizontal";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import  API_BASE_URL  from "../../API/ConfigAPI";
import "./userslist.css";
function UsersList() {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    id: null,
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    is_admin: false,
    password: "",
    societes: [],
  });
  const [error, setError] = useState("");
  const [availableSocietes, setAvailableSocietes] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMode, setModalMode] = useState("add"); // 'add' ou 'edit'
 
  const fetchUsers = () => {
    const token = localStorage.getItem("token");
    axios.get(`${API_BASE_URL}/home/api/users/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((response) => {
      setUsers(response.data);
    })
    .catch((error) => console.error("Erreur lors de la récupération des utilisateurs:", error));
  };
  
  useEffect(() => {
    fetchUsers();
  }, []); // Les crochets vides indiquent que cet effet ne dépend d'aucune variable d'état et ne s'exécute qu'une fois après le premier rendu
  
  // Créer une liste unique de toutes les sociétés
  
  useEffect(() => {
    if (isModalOpen) {
      // Ajoute la classe pour désactiver le défilement
      document.body.classList.add("body-no-scroll");
    } else {
      // Retire la classe pour réactiver le défilement
      document.body.classList.remove("body-no-scroll");
    }

    return () => {
      // Nettoie lorsque le composant est démonté
      document.body.classList.remove("body-no-scroll");
    };
  }, [isModalOpen]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${API_BASE_URL}/home/api/users/`, {
        headers: {
          Authorization: `Token ${token}`, // Utilisez le token dans l'en-tête Authorization
        },
      })
      .then((response) => setUsers(response.data))
      .catch((error) =>
        console.error("Erreur lors de la récupération des utilisateurs:", error)
      );
    const table = document.querySelector("table");
    const rowCount = table.getElementsByTagName("tr").length;

    // Définir un seuil pour activer la barre de défilement
    const seuil = 14;

    if (rowCount > seuil) {
      table.style.maxHeight = "500px"; // Définir une hauteur maximale pour la table
      table.style.overflowY = "auto"; // Activer la barre de défilement
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${API_BASE_URL}/home/api/societes/`, {
        headers: {
          Authorization: `Token ${token}`, // Utilisez le token dans l'en-tête Authorization
        },
        params: {
          gestionnaire_vide: "true", // Ajoutez ce paramètre pour indiquer le filtrage désiré
        },
      })
      .then((response) => setAvailableSocietes(response.data))
      .catch((error) =>
        console.error("Erreur lors de la récupération des sociétés:", error)
      );
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCurrentUser({
      ...currentUser,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche la soumission par défaut du formulaire HTML

    const token = localStorage.getItem("token");
    if (!token) {
        setError("Vous n'êtes pas connecté.");
        return;
    }

    const config = {
        headers: {
            Authorization: `Token ${token}`,
        },
    };

    const userData = {
        username: currentUser.username,
        email: currentUser.email,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        is_admin: currentUser.is_admin,
        ...(modalMode === "add" && { password: currentUser.password }),
    };

    try {
        if (currentUser.id) {
            await axios.put(
                `${API_BASE_URL}/home/api/users/${currentUser.id}/`,
                userData,
                config
            );
        } else {
            await axios.post(
                `${API_BASE_URL}/home/api/users/`,
                userData,
                config
            );
        }

        if (
            currentUser.id &&
            modalMode === "edit" &&
            currentUser.societes.length
        ) {
            await axios.post(
                `${API_BASE_URL}/home/api/societes/reset/${currentUser.id}/`,
                {},
                config
            );

            const societeUpdates = currentUser.societes.map((societe) => {
                const societeData = {
                    gestionnaire: currentUser.id,
                    siret: societe.siret,
                    nom: societe.nom,
                };

                return axios.put(
                    `${API_BASE_URL}/home/api/societes/${societe.id}/`,
                    societeData,
                    config
                );
            });

            await Promise.all(societeUpdates);
        }

        toast.success("Utilisateur mis à jour avec succès !");
    } catch (error) {
        if (error.response && error.response.data) {
            Object.keys(error.response.data).forEach((key) => {
                const errorMessage = error.response.data[key];
                if (Array.isArray(errorMessage)) {
                    errorMessage.forEach((message) => {
                        toast.error(`${key}: ${message}`);
                    });
                } else {
                    toast.error(`${key}: ${errorMessage}`);
                }
            });
        } else {
            toast.error("Une erreur s'est produite lors de la mise à jour des données.");
        }
    } finally {
        setTimeout(() => {
            closeModal(); // Fermer la modale après un délai
        }, 100); // Délai de 100ms pour garantir que le formulaire est traité avant la fermeture de la modale
        fetchUsers();
        setCurrentUser({
            id: null,
            username: "",
            email: "",
            first_name: "",
            last_name: "",
            is_admin: false,
            password: "",
            societes: [],
        });
    }
};

  

  const openModal = (user = null) => {
    const mode = user ? "edit" : "add";
    setModalMode(mode);
    setIsModalOpen(true);

    if (user) {
      // En mode "edit", on garde la possibilité de modifier les sociétés
      setCurrentUser({ ...user, password: "" }); // Conservez les sociétés existantes pour un utilisateur existant
    } else {
      // En mode "add", on initialise sans sociétés
      setCurrentUser({
        id: null,
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        is_admin: false,
        password: "",
        societes: [], // Aucune société sélectionnée par défaut pour un nouvel utilisateur
      });
    }
    setModalTitle(user ? "Modifier un utilisateur" : "Ajouter un utilisateur");
  };

  const handleDelete = (userId) => {
    const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?");
    if (!isConfirmed) {
      return; // Si l'utilisateur clique sur "Annuler", ne faites rien
    }
    const token = localStorage.getItem("token");

    axios
      .delete(`${API_BASE_URL}/home/api/users/${userId}/`, {
        headers: {
          Authorization: `Token ${token}`, // Utilisez le token dans l'en-tête Authorization
        },
      })

      .then(() => {
        // Ici, vous pouvez afficher un toast de réussite
        toast.success("Utilisateur supprimé avec succès !");
        // Mettre à jour l'état pour enlever l'utilisateur de la liste
        setUsers(users.filter((user) => user.id !== userId));
      })
      
      .catch((error) => {
        // Si vous avez des messages d'erreur spécifiques renvoyés par le serveur, affichez-les ici
        const errorMessage = error.response?.data?.detail || "Erreur lors de la suppression de l’utilisateur.";
        toast.error(errorMessage);
        console.error("Erreur lors de la suppression de l’utilisateur:", error);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="parent-layout-usres">
      <BarreNavigation />
      <div className="content-users">
        
          <Navbarhorizontal />
       
        <div className="container">
          <h1>Gestion des habilitations</h1>
          <button className="btn-ajout" onClick={() => openModal()}>
            Ajouter un utilisateur
          </button>
          
          <div className="table-container">
            <table className="users_table"> 
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Prénom</th>
                  <th>Nom de famille</th>
                  <th>Adresse Email</th>
                  <th>Admin</th>
                  {/* <th>Sociétés</th> */}
                  <th >Actions</th>
                </tr>
              </thead>
              <tbody>
                {users
                 
                  .map((user) => (
                    <tr key={user.id}>
                      <td>{user.username}</td>
                      <td>{user.first_name}</td>
                      <td>{user.last_name}</td>
                      <td>{user.email}</td>
                      <td>{user.is_admin ? "Oui" : "Non"}</td>
                      {/* <td className="societes-cell" >
                        {user.societes?.map((societe, index) => (
                          <span key={index}>
                            {" "}
                            * {societe.nom} - siret {societe.siret} <br />
                          </span>
                        ))}
                      </td> */}
                      <td>
                        <div className="btn-container">
                          <button
                            className="edit-btn"
                            onClick={() => openModal(user)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                          <button
                            className="delete-btn"
                            onClick={() => handleDelete(user.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

         

          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              title={modalTitle}
            >
              <div className="modal-body">
                <form onSubmit={handleSubmit} className="form-field" > 
                  {error && <div style={{ color: "red" }}>{error}</div>}
                  <div className="flex">
                    <div className="input-group">
                      <label htmlFor="username"> Nom d'utilisateur </label>
                      <input
                        id="username"
                        type="text"
                        name="username"
                        value={currentUser.username}
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="email"> Email </label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={currentUser.email}
                        onChange={handleInputChange}
                        required
                        autoComplete="email"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="input-group">
                      <label htmlFor="first_name"> Prénom </label>
                      <input
                        id="first_name"
                        type="text"
                        name="first_name"
                        value={currentUser.first_name}
                        onChange={handleInputChange}
                        autoComplete="given-name"
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="last_name"> Nom de famille </label>
                      <input
                        id="last_name"
                        type="text"
                        name="last_name"
                        value={currentUser.last_name}
                        onChange={handleInputChange}
                        autoComplete="family-name"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    {modalMode === "add" && (
                      <div className="input-group">
                        <label htmlFor="password">Mot de passe</label>
                        <input
                          id="password"
                          type="password"
                          name="password"
                          value={currentUser.password}
                          onChange={handleInputChange}
                          required
                          autoComplete="off"
                        />
                      </div>
                    )}
                    <div className="input-group">
                      <div>
                        <label htmlFor="is_admin">
                          Admin :
                          <input
                            id="is_admin"
                            type="checkbox"
                            name="is_admin"
                            checked={currentUser.is_admin}
                            autoComplete="off"
                            onChange={(e) =>
                              handleInputChange({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              })
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div>
                      {modalMode === "edit" && (
                        <div className="societes-scroll-container">
                          <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            style={{ width: 500, marginBottom: "0px" }}
                            options={availableSocietes} // Utilisez les sociétés disponibles ici
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.nom} // Assurez-vous que 'nom' est la propriété à afficher
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.nom} - {option.siret}
                              </React.Fragment>
                            )}
                            value={currentUser.societes} // Les sociétés actuellement sélectionnées
                            onChange={(event, newValue) => {
                              console.log(
                                "Nouvelles valeurs sélectionnées:",
                                newValue
                              );

                              // Fusionner les nouvelles valeurs avec les anciennes, sans doublons
                              const uniqueSocietes = [
                                ...new Map(
                                  [...currentUser.societes, ...newValue].map(
                                    (societe) => [societe["siret"], societe]
                                  )
                                ).values(),
                              ];

                              setCurrentUser({
                                ...currentUser,
                                societes: uniqueSocietes,
                              });
                              setCurrentUser({
                                ...currentUser,
                                societes: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                id="Sociétés associées"
                                name="Sociétés associées"
                                label="Sociétés associées"
                                placeholder="Sélectionnez"
                                // style={{height : 400}}
                                autocomplete="off"  // Ajout de l'attribut autocomplete

                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-actions">
                    <button onClick={closeModal} className="cancel-button">
                      Fermer
                    </button>
                    <button type="submit" className="submit-button">
                      Sauvegarder
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}

export default UsersList;
