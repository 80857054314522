import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./Navbarhorizontal.css";

function Navbarhorizontal() {
  const navigate = useNavigate();
  
  // Récupération du nom d'utilisateur depuis le stockage local
  const username = localStorage.getItem('username');

  // Fonction pour gérer la déconnexion
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('is_admin'); // Supprimez le token de localStorage
    localStorage.removeItem('username'); // Supprimez le nom d'utilisateur de localStorage
    navigate('/login'); // Redirigez l'utilisateur vers la page de connexion
  };

  return (
    <nav className="main-nav">
        <div className="main-nav-logo">
          {/* <img src={logo} alt="Logo" width={100}/> */}
        </div>
        <div className="navbar_loginSuccess">
          {/* Affiche le nom d'utilisateur s'il est connecté */}
          {username && (
            <p className="main-nav-item">
              {username}
              <i className="fa fa-user-circle fa-lg"></i>
              
            </p>
          )}
          {/* Bouton de déconnexion */}
          <p className="main-nav-item" onClick={handleLogout}>
          Déconnexion
            <i className="fa fa-sign-out fa-lg" ></i>
        
          </p>
        </div>
    </nav>
  );
}

export default Navbarhorizontal;
