import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend,Tooltip, ResponsiveContainer} from 'recharts';
import "./statiquestatut.css"

const StatistiqueStatut = ({ facturations, selectedMonth, onTotalFacturationsChange }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [totalFacturations, setTotalFacturations] = useState(0);

  useEffect(() => {
    const formatMonthYear = (date) => {
      const monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", 
                          "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
      const [year, month] = date.split("-");
      return `${monthNames[parseInt(month) - 1]}-${year}`;
    };

    const initialStats = {
        "Terminé": 0,
        "A faire": 0,
        "En revue": 0,
        "A revoir": 0
        
      };

      const filteredFacturations = facturations.filter(fact => fact.mois_facturation === formatMonthYear(selectedMonth));
      const statsData = filteredFacturations.reduce((acc, { statut }) => {
        acc[statut] = (acc[statut] || 0) + 1;
        return acc;
      }, initialStats);
      

    setFilteredData(Object.keys(statsData).map(key => ({
      name: key,
      value: statsData[key]
    })));

    // Calcul du total des facturations pour le mois sélectionné
    const totalFacturations = filteredFacturations.length;
    //console.log("Total facturations calculated:", totalFacturations);

    setTotalFacturations(totalFacturations);
    onTotalFacturationsChange(totalFacturations);
  }, [selectedMonth, facturations, onTotalFacturationsChange]);

  const STATUT_COLORS = {
    "A faire": "#C4D4CB",
    "En revue": "#CE7C2D",
    "A revoir": "#D9D9D9",
    "Terminé": "#FCC712"
  };

  return (
    <div className='container-statistique-statut'>
      <h2 className='title-statiquestatut ' >déclaration des prestations</h2>
      <div style={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={filteredData}
          cx="50%"
          cy="60%"
          startAngle={180}
          endAngle={0}
          innerRadius={90}
          outerRadius={120}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
          
        >
          {filteredData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={STATUT_COLORS[entry.name]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend
         className="my-custom-legend"
          layout="horizontal"
          horizOriginX="middle"
          style={{ marginLeft: '0px', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333'}}
          padd
        />
      </PieChart>
      </ResponsiveContainer>
      </div>
      <div className='total-facturations'>
        <h2>{totalFacturations}</h2>
      </div>
    </div>
  );
};

export default StatistiqueStatut;
