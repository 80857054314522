import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { toast } from 'react-toastify';
import image from '../../assets/imagelogin.png';
import './loginpage.css';
import  API_BASE_URL  from "../../API/ConfigAPI";

function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); 
    const navigate = useNavigate();

    const fetchUserDetails = async (userId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_BASE_URL}/home/api/users/${userId}/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            localStorage.setItem('is_admin', JSON.stringify(response.data.is_admin));
            localStorage.setItem('username', username);
            localStorage.setItem('userId',response.data.id);

            navigate(response.data.is_admin ? '/users' : '/facturation');
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l\'utilisateur', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/api/login/`, {
                username,
                password
            });
            if (response.data.success) {
                localStorage.setItem('token', response.data.token);
                fetchUserDetails(response.data.user_id); // Appelle la fonction pour récupérer les détails de l'utilisateur
                toast.success("Connexion réussie !");
            } else {
                setErrorMessage("Identifiants invalides.");
                toast.error("Identifiants invalides.");
            }
        } catch (error) {
            console.error('Erreur de connexion', error);
            setErrorMessage("Identifiants invalides.");
        toast.error("Identifiants invalides.");
        }
    };

    return (
        <div className="login-container">
            <div className="login-banner">
                <img src={image} alt="Login Banner" />
            </div>
            <div className="login-form-container">
                <h1 className='title-login'>Se connecter</h1>
                <form onSubmit={handleSubmit} className='form_login'>
                    <div className="input-group">
                        <input
                            id='username'
                            type="text"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Identifiant"
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            id='password'
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Mot de passe"
                            required
                        />
                    </div>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    <button type="submit" className="login-button">Connexion</button>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;