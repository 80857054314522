import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children, allowedAdmins = [] }) => {
    const location = useLocation();
    const isAdmin = JSON.parse(localStorage.getItem('is_admin'));
    const currentUserId = localStorage.getItem('userId'); // Récupérer l'ID de l'utilisateur connecté

    if (!isAdmin || (allowedAdmins.length > 0 && !allowedAdmins.includes(currentUserId))) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default PrivateRoute;
