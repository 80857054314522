import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const StatistiqueEnvoiMailMois = ({ facturations, selectedMonth }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const calculatePercentages = () => {
      // Convert selectedMonth to year and month
      const [year, month] = selectedMonth.split('-').map(Number);

       // Convert month number to month name
       const monthNames = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
      ];

      const monthName = monthNames[month - 1]; // -1 because array is zero-indexed

      // Build formatted selectedMonth for comparison
      const formattedSelectedMonth = `${monthName}-${year}`;

      // Filter facturations for the selected month using the formattedSelectedMonth
      const facturationsInSelectedMonth = facturations.filter(fact => fact.mois_facturation === formattedSelectedMonth);

      //console.log(formattedSelectedMonth);
     // console.log("facturationmois" ,facturationsInSelectedMonth);
      const dateRanges = [
        { start: 1, end: 15, monthOffset: 0 }, // Selected month
        { start: 16, end: new Date(year, month, 0).getDate(), monthOffset: 0 }, // Last day of the selected month
        { start: 1, end: 15, monthOffset: 1 }, // Next month
        { start: 16, end: new Date(year, month, 0).getDate(), monthOffset: 1 } // Next month
        
      ];

      const totals = dateRanges.map(range => {
        const start = new Date(year, month - 1+ range.monthOffset, range.start);
        const end = new Date(year, month -1 + range.monthOffset, range.end);
       // console.log("start",start);
       // console.log("end",end);
        const count = facturationsInSelectedMonth.filter(fact => {
          const dateEnvoi = new Date(fact.date_envoi_mail);
          return dateEnvoi >= start && dateEnvoi <= end;
        }).length;
        const percentage = Math.round((count / facturationsInSelectedMonth.length) * 100); 
       // console.log(count);
       // console.log(facturationsInSelectedMonth.length);

        // Format date range to the desired format
// Assurez-vous que le mois est formaté correctement (01, 02, ..., 12)
const formattedMonth = String(start.getMonth() + 1).padStart(2, '0');

const formattedDate = `${String(range.start).padStart(2, '0')} au ${String(range.end).padStart(2, '0')}/${formattedMonth}`;

        return { date: formattedDate, percentage }; 
      });
      

      setData(totals);
    };

    if (facturations.length > 0) {
      calculatePercentages();
    }
  }, [selectedMonth, facturations]);
  

// Couleurs assignées à chaque période
  const COLORS = ['#086532', '#045674', '#FFBB28', '#FF8042'];
  return (
    <div className="container-statistique-statut">
      <h2 className="title-statiquestatut">Date d’envoi des factures</h2>
      <ResponsiveContainer width="100%" height={340}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip formatter={(value) => `${value}%`} />
          <Bar
            dataKey="percentage"
            fill="#8884d8"
            label={{
              position: 'top',
              formatter: (value) => `${value}%`, // Format the label with percentage sign
            }}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StatistiqueEnvoiMailMois;
