
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import API_BASE_URL from "../../API/ConfigAPI";
import StatistiqueStatut from "../../components/StatistiqueStatut/StatistiqueStatut";
import BarreNavigation from "../../components/BarreNavigation/BarreNavigation";
import Navbarhorizontal from "../../components/Navbarhorizontal/Navbarhorizontal";
import StatistiqueEnvoiMail from "../../components/StatistiqueEnvoiMail/StatistiqueEnvoiMail";
import StatistiqueEnvoiMailMois from "../../components/StatistiqueEnvoiMailMois/StatistiqueEnvoiMailMois";
import StatistiquePrestation from "../../components/StatistiquePrestation/StatistiquePrestation";
import StatistiqueCAGestionnaire from "../../components/StatistiqueCAGestionnaire/StatistiqueCAGestionnaire";
import ChiffresCles from "../../components/ChiffresCles/ChiffresCles";
import EvolutionCA from "../../components/EvalutionCA/EvalutionCA";
import "./statiquespage.css";
import EvolutionPortefeuille from "../../components/EvolutionPortefeuille/EvolutionPortefeuille";

const StatistiquesPage = () => {
    const [facturations, setFacturations] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7));
    const [keyFigures, setKeyFigures] = useState({ totalCA: 0, nbFactures: 0, topGestionnaire: '' });
    const [caMonthlyData, setCaMonthlyData] = useState([]);
     // eslint-disable-next-line 
    const [totalFacturations, setTotalFacturations] = useState(0); // Ajoutez cette ligne
    const [portefeuilleData, setPortefeuilleData] = useState([]);



    useEffect(() => {
        const fetchFacturations = async () => {
            const token = localStorage.getItem("token");
            try {
                const response = await axios.get(`${API_BASE_URL}/home/api/facturations/`, {
                    headers: { Authorization: `Token ${token}` }
                });
                const data = response.data;
                setFacturations(data);
                calculateCAMonthly(data);
                updateMonthlyStatistics(data);
                calculatePortfolioData(data); // Ajoutez cet appel ici

            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
            }
        };

        fetchFacturations();
        // eslint-disable-next-line
    }, [selectedMonth]);


    const handleTotalFacturationsChange = useCallback((total) => {
      setTotalFacturations(total); // Utilisez cette fonction pour mettre à jour totalFacturations
  }, []);

    const handleDataCalculate = useCallback((data) => {
        //console.log("Data Calculated in StatistiquesPage:", data);
        setKeyFigures(prev => ({
            ...prev,
            totalCA: data.totalCA,
            topGestionnaire: data.topGestionnaire
        }));
    }, []);

    const formatMonthYear = (date) => {
        const monthYearPattern = /(\d{4})-(\d{2})/; // Regex pour extraire année et mois
        const match = date.match(monthYearPattern);
        if (match) {
            const year = match[1];
            const month = parseInt(match[2], 10) - 1;
            const monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
            return `${monthNames[month]}-${year}`;
        }
        return date; // Retourner la date non modifiée si le pattern ne correspond pas
    };
    

    const [displayMonth, setDisplayMonth] = useState(formatMonthYear(new Date().toISOString().slice(0, 7)));

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
        setDisplayMonth(formatMonthYear(e.target.value));
    };



    const calculateCAMonthly = (facturations) => {
        const caByMonth = facturations.reduce((acc, fact) => {
            if (fact.statut === "Terminé") {  // Only include facturations with status "Terminé"
                const monthYear = fact.mois_facturation
                const ca = parseFloat(fact.total_ttc.toFixed(2)); // Arrondir à deux chiffres après la virgule
    
                if (acc[monthYear]) {
                    acc[monthYear] += ca;
                } else {
                    acc[monthYear] = ca;
                }
            }
            return acc;
        }, {});
    
        const formattedData = Object.entries(caByMonth).map(([month, ca]) => ({
            month, ca
        }));
    
        setCaMonthlyData(formattedData);
    }; 
    
    
    const calculatePortfolioData = (facturations) => {
        const dataByMonth = facturations.reduce((acc, fact) => {
            const monthYear = formatMonthYear(fact.mois_facturation);
            if (!acc[monthYear]) {
                acc[monthYear] = { nbCompanies: new Set(), nbFactures: 0 };
            }
            
            acc[monthYear].nbCompanies.add(fact.siret);
    
            if (fact.statut === "Terminé") {
                acc[monthYear].nbFactures += 1;
            }
    
            return acc;
        }, {});
    
        const formattedData = Object.entries(dataByMonth).map(([month, data]) => {
            return {
                month,
                nbCompanies: data.nbCompanies.size,
                nbFactures: data.nbFactures
            };
        });
    
        setPortefeuilleData(formattedData);
    };
    
    

    const updateMonthlyStatistics = (data) => {
        const nbFacturesTerminees = data.filter(fact =>
            fact.statut === "Terminé" &&
            fact.mois_facturation.startsWith(displayMonth)
        ).length;
    
        setKeyFigures(prevState => ({
            ...prevState,
            nbFactures: nbFacturesTerminees
        }));
    };
    
    useEffect(() => {
        const fetchFacturations = async () => {
            const token = localStorage.getItem("token");
            try {
                const response = await axios.get(`${API_BASE_URL}/home/api/facturations/`, {
                    headers: { Authorization: `Token ${token}` }
                });
                const data = response.data;
                setFacturations(data);
                calculateCAMonthly(data);
                updateMonthlyStatistics(data);
                calculatePortfolioData(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
            }
        };
    
        fetchFacturations();
         // eslint-disable-next-line 
    }, [selectedMonth]);
    
    //console.log("Rendering StatistiquesPage with keyFigures:", keyFigures);

    return (
        <div className="parent-layout-statiquestatut">
            <BarreNavigation />
            <div className="content-statiquespage">
                <Navbarhorizontal />
                <h1>Le tableau de bord</h1>
                <p className="soustitle">Ce graphique représente la synthèse des données issues des facturations DU MOIS DE "{formatMonthYear(selectedMonth)}"</p>
                <div className="choisi-mois">
                    <label htmlFor="Choisir_le_mois">Choisir le mois: </label>
                    <input 
                    id="Choisir_le_mois"
                    name="Choisir_le_mois"
                    type="month" 
                    value={selectedMonth} 
                    onChange={handleMonthChange} 
                    />
                </div>
                <div className="containair-Statistiqes">
                    <StatistiqueStatut facturations={facturations} selectedMonth={selectedMonth} onTotalFacturationsChange={handleTotalFacturationsChange} />
                    <StatistiqueEnvoiMail facturations={facturations} selectedMonth={selectedMonth} />
                    <StatistiqueEnvoiMailMois facturations={facturations} selectedMonth={selectedMonth} />
                    <StatistiquePrestation facturations={facturations} selectedMonth={displayMonth} />
                    <StatistiqueCAGestionnaire facturations={facturations} selectedMonth={displayMonth} onDataCalculate={handleDataCalculate} />
                    <ChiffresCles {...keyFigures}/>
                    <EvolutionCA data={caMonthlyData} />
                    <EvolutionPortefeuille data={portefeuilleData}/>
                </div>
            </div>
        </div>
    );
};

export default StatistiquesPage;
