import React from 'react';
import "./chiffrescles.css"
const ChiffresCles = ({ totalCA, topGestionnaire,nbFactures }) => {
  // Vérifiez si totalCA est un nombre et formatez-le, sinon gardez-le tel quel
 //console.log("Props in ChiffresCles:", { totalCA, nbFactures, topGestionnaire });


  return (
    <div className='container-statistique-statut'>
     <h2 className='title-statiquestatut ' >chifFres clés</h2>
     <div className="chiffres-cles">
      <div className="stat-container">
        <div className="stat-block ca-total">
          <span>Chiffre D'affaire</span>
          <strong className='text-ca-total'>{totalCA.toFixed(2)}€</strong>
        </div>
        <div className="stat-block nbfacture">
          <span>NB factures terminées</span>
          <strong className='text-nbfacture'>{nbFactures}</strong>
        </div>
        <div className="stat-block top-gestionnaire">
          <span>Talent du mois</span>
          <strong className='text-top-gestionnaire'>{topGestionnaire}</strong>
        </div>
      
      </div>
      </div>
    </div>
  );
};

export default ChiffresCles;
