import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const EvolutionPortefeuille = ({ data }) => {

  // Map of month names to their respective indices
  const monthToIndex = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
  };

  // Format and sort the data
  const formattedData = data.map(item => {
    const [monthName, year] = item.month.split('-');
    const monthIndex = monthToIndex[monthName.toLowerCase()];

    // Create a Date object to format the month name
    const date = new Date(year, monthIndex);
    const monthFormatted = date.toLocaleString('fr-FR', { month: 'long' });

    return {
      ...item,
      month: monthFormatted.charAt(0).toUpperCase() + monthFormatted.slice(1), // Capitalize
      monthIndex, // Add the month index to use for sorting
      year: parseInt(year) // Ensure the year is an integer for sorting
    };
  }).sort((a, b) => (a.year - b.year) || (a.monthIndex - b.monthIndex)); // Sort by year and month index

  return (
    <div className='container-statistique-statut'>
      <h2 className='title-statiquestatut'>Évolution du portefeuille</h2>
      <ResponsiveContainer width="100%" height={320}>
        <BarChart data={formattedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="nbCompanies" name="Nb de sociétés à facturer" fill="#D3D3D3" stackId="a" stroke="#FF0000" strokeWidth={0.5} />
          <Bar dataKey="nbFactures" name="Nb de sociétés facturées" fill="#82ca9d" stackId="b" stroke="#008000" strokeWidth={0.5} />

        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EvolutionPortefeuille;
