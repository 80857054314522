import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AuthRoute = ({ children }) => {
    const location = useLocation();
    const isAuthenticated = Boolean(localStorage.getItem('token')); 
  
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children; // Permet l'accès si authentifié
  };
  

export default AuthRoute;
