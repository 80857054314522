import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import  API_BASE_URL  from "../../API/ConfigAPI";


import Modal from "../../components/Modal/Modal";
import BarreNavigation from "../../components/BarreNavigation/BarreNavigation";
import Navbarhorizontal from "../../components/Navbarhorizontal/Navbarhorizontal";
import '../../ToastifyCustom.css';
import "./companylist.css";

function CompanyList() {
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  // Ajoutez l'état pour gérer l'affichage du modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCompany, setNewCompany] = useState({
    siret: "",
    nom: "",
    adresse: "",
    ville: "",
    code_postal: "",
    email: "",
    // gestionnaire_nom: "",
    societe_gestionnaire: "E2P",
    compte: "",
    compte_de_base: "412",
    montant_ftc: 0,
    tarif_subvention: 0,
    tarif_unitaire_bulletin_unitaire: 0,
    tarif_teulade: 0,

    tarif_RC: 0,
    tarif_licenciement: 0,
    tarif_contrat: 0,
    tarif_maladie: 0,
    tarif_avenant: 0,
    tarif_mission_excep: 0,
    tarif_stc: 0,
    gestionnaire: null,
    societe_a_facturer: "Oui", // Default value for the toggle switch

  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingCompany, setEditingCompany] = useState(null);
  const [formError, setFormError] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Nouvel état pour la recherche

  const [isFacturationModalOpen, setIsFacturationModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");


  const fetchCompanies = () => {
    const token = localStorage.getItem("token");
    axios.get(`${API_BASE_URL}/home/api/societes/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((response) => {
      setCompanies(response.data);
    })
    .catch((error) => console.error("Erreur lors de la récupération des sociétés:", error));
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${API_BASE_URL}/home/api/societes/`, {
        headers: {
          Authorization: `Token ${token}`, // Utilisez le token dans l'en-tête Authorization
        },
      })
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des sociétés:", error);
      });
    const table = document.querySelector("table");
    const rowCount = table.getElementsByTagName("tr").length;

    // Définir un seuil pour activer la barre de défilement
    const seuil = 14;

    if (rowCount > seuil) {
      table.style.maxHeight = "500px"; // Définir une hauteur maximale pour la table
      table.style.overflowY = "auto"; // Activer la barre de défilement
    }
    // Chargement des utilisateurs (gestionnaires)

    axios
      .get(`${API_BASE_URL}/home/api/users/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setUsers(response.data);
       
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des utilisateurs:",
          error
        );
      });
  }, []);

  const handleDelete = (companyId) => {
    const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette société ?");
    if (!isConfirmed) {
      return; // Si l'utilisateur clique sur "Annuler", ne faites rien
    }
    const token = localStorage.getItem("token");
    axios
      .delete(`${API_BASE_URL}/home/api/societes/${companyId}/`, {
        headers: {
          Authorization: `Token ${token}`, // Utilisez le token dans l'en-tête Authorization
        },
      })
      .then((response) => {
        // Suppression réussie, mettez à jour l'état pour enlever la société supprimée
        setCompanies(companies.filter((company) => company.id !== companyId));
        toast.success("Société supprimée avec succès !", {
          className: "myToastClassNamesuccés",
          progressClassName: "myToastProgressClassName"
        });
        fetchCompanies();
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression de la société:", error);
        toast.error("Erreur lors de la suppression de la société.", {
          className: "myToastClassNameerreur",
          progressClassName: "myToastProgressClassName"
        });
      });
  };

  const handleEditClick = (company) => {
    setEditingCompany(company); // Stocke la société à modifier
    setNewCompany({
      siret: company.siret,
      nom: company.nom,
      adresse: company.adresse,
      ville: company.ville,
      code_postal: company.code_postal,
      email: company.email,
      gestionnaire_nom: company.gestionnaire_nom,
      societe_gestionnaire: company.societe_gestionnaire,
      compte: company.compte,
      compte_de_base: company.compte_de_base,
      montant_ftc: company.montant_ftc,
      tarif_subvention: company.tarif_subvention,
      tarif_unitaire_bulletin_unitaire:
        company.tarif_unitaire_bulletin_unitaire,
      tarif_teulade: company.tarif_teulade,
      tarif_acces_silae: company.tarif_acces_silae,
      tarif_RC: company.tarif_RC,
      tarif_licenciement: company.tarif_licenciement,
      tarif_contrat: company.tarif_contrat,
      tarif_maladie: company.tarif_maladie,
      tarif_avenant: company.tarif_avenant,
      tarif_mission_excep: company.tarif_mission_excep,
      tarif_stc: company.tarif_stc,
      entite_facturation: company.entite_facturation,
      gestionnaire: company.gestionnaire,
      societe_a_facturer: company.societe_a_facturer || "Oui",

    }); // Pré-remplit le formulaire
    setIsEditModalOpen(true); // Ouvre le modal de modification
    setModalTitle("METTRE À JOUR LES INFORMATIONS");
  };
 // Fonction pour gérer l'ajout d'une nouvelle société pour les champs non tarifaires
const handleInputChange = (e) => {
  const { name, value } = e.target;

  if (name === "gestionnaire_nom") {
    const selectedUser = users.find((user) => user.id.toString() === value);
    setNewCompany({
      ...newCompany,
      gestionnaire: selectedUser ? selectedUser.id : null,
    });
  } else {
    setNewCompany({ ...newCompany, [name]: value });
  }
};

const handleTarifInputChange = (e) => {
  const { name, value } = e.target;
  
  // Autorise explicitement la chaîne vide pour permettre d'effacer le champ
  if (value === '') {
    setNewCompany({ ...newCompany, [name]: '' });
    return; // Arrête la fonction ici pour ne pas aller plus loin dans la validation
  }

  // Continue avec la validation pour les nombres avec virgules
  // eslint-disable-next-line 
  const regex = /^\d+(\,\d{0,2})?$/; // Accepte les nombres avec jusqu'à deux chiffres après la virgule
  
  if (regex.test(value) || value === '') {
    // Remplace les virgules par des points pour la conversion en nombre
    setNewCompany({ ...newCompany, [name]: value.replace(',', '.') });
  } else {
    // Si la valeur n'est pas valide, vous pourriez vouloir remettre la valeur précédente ou afficher un message d'erreur
    // Mais pour ce cas, ne fait rien de plus ici pour ne pas réintroduire une valeur invalide.
  }
};

const handleToggleChange = () => {
  setNewCompany({
    ...newCompany,
    societe_a_facturer: newCompany.societe_a_facturer === "Oui" ? "Non" : "Oui",
  });
};
const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};
  
const [lastCreatedSiret, setLastCreatedSiret] = useState(null); // Nouvel état pour stocker le dernier SIRET créé


const handleSubmit = (e) => {
  e.preventDefault();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Token ${token}`,
  };
  const url = editingCompany
    ? `${API_BASE_URL}/home/api/societes/${editingCompany.id}/`
    : `${API_BASE_URL}/home/api/societes/`;
  const method = editingCompany ? "put" : "post";

  axios[method](url, newCompany, { headers })
    .then((response) => {
      const successMessage = editingCompany
        ? "Société modifiée avec succès !"
        : "Société ajoutée avec succès !";
      toast.success(successMessage, {
        className: "myToastClassNamesuccés",
        progressClassName: "myToastProgressClassName"
      });

      if (!editingCompany) {
        setCompanies([...companies, response.data]);
        setLastCreatedSiret(response.data.siret); // Stockez le SIRET de la société nouvellement créée
        confirmFacturation(response.data.siret); // Demander confirmation pour la facturation pour une nouvelle société
      } else {
        setCompanies(
          companies.map((company) =>
            company.id === editingCompany.id ? response.data : company
          )
        );
      }

      setIsModalOpen(false);
      setIsEditModalOpen(false);
      resetNewCompanyForm();
      fetchCompanies(); 
    })
    .catch(handleError);
};

const confirmFacturation = (siret) => {
  if (window.confirm("Voulez-vous générer la facturation pour cette nouvelle société ?")) {
    askForFacturation(siret);
  }
};

const getPreviousMonths = () => {
  const today = new Date();
  const monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
  const currentMonth = `${monthNames[today.getMonth()]}-${today.getFullYear()}`;
  const lastMonth = today.getMonth() === 0 ? `${monthNames[11]}-${today.getFullYear() - 1}` : `${monthNames[today.getMonth() - 1]}-${today.getFullYear()}`;
  return [currentMonth, lastMonth];
};

const handleMonthSelection = (siret) => {
  const selectedMonth = document.getElementById("month-selection").value;
  if (selectedMonth) {
    generateFacturation(siret, selectedMonth);
    setIsFacturationModalOpen(false); // Close the modal after selection
  }
};

const askForFacturation = (siret) => {
  setSelectedMonth(getPreviousMonths()[0]); // Set default to current month
  setIsFacturationModalOpen(true);
};

// const selectFacturationMonth = (siret) => {
// const monthYear = prompt("Entrez le mois et l'année pour la facturation (par exemple, 'mars-2024'):");
// if (monthYear) {
//   generateFacturation(siret, monthYear);
// }
// };

const generateFacturation = (siret, moisFacturation) => {
  const token = localStorage.getItem("token");
  const url = `${API_BASE_URL}/home/api/generate-facturations/${siret}/${moisFacturation}/`; // Mettez le siret et le mois directement dans l'URL
  const data = {
    siret: siret,
    moisFacturation: moisFacturation
  };
  axios.post(url, data, { 
    headers: {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json'
    },
  })
  .then(response => {
    console.log("Facturation générée avec succès", response);
    toast.success("Facturation générée avec succès");
  })
  .catch(error => {
    console.error("Erreur lors de la génération de la Facturation", error);
    toast.error("Erreur lors de la génération de la Facturation");
  });
};

const resetNewCompanyForm = () => {
  setNewCompany({
    nom: "",
    adresse: "",
    ville: "",
    code_postal: "",
    email: "",
    societe_gestionnaire: "E2P",
    compte: "",
    compte_de_base: "412",
    montant_ftc: 0,
    tarif_unitaire_bulletin_unitaire: 0,
    tarif_teulade: 0,
    tarif_acces_silae: 0,
    tarif_RC: 0,
    tarif_licenciement: 0,
    tarif_contrat: 0,
    tarif_maladie: 0,
    tarif_avenant: 0,
    tarif_mission_excep: 0,
    tarif_stc: 0,
    entite_facturation: "",
    gestionnaire: null,
    societe_a_facturer: "Oui", // Reset to default value

  });
  setEditingCompany(null);
  setFormError("");
};

const handleError = (error) => {
  console.error("Erreur lors de la soumission du formulaire:", error);
  toast.error("Une erreur s'est produite lors de l'envoi du formulaire.", {
    className: "myToastClassNameerreur",
    progressClassName: "myToastProgressClassName"
  });
};

  const openAddModal = () => {
    setIsModalOpen(true);
    setModalTitle("CRÉER UNE NOUVELLE SOCIÉTÉ");
    // Réinitialiser le formulaire et les erreurs lors de l'ouverture du modal d'ajout
    setNewCompany({
      nom: "",
      adresse: "",
      ville: "",
      code_postal: "",
      email: "",
      // gestionnaire_nom: "",
      societe_gestionnaire: "E2P",
      compte: "",
      compte_de_base: "412",
      montant_ftc: 0 ,
      tarif_acces_silae: 0,
      tarif_unitaire_bulletin_unitaire: 0,
      tarif_teulade: 0,

      tarif_RC: 0,
      tarif_licenciement: 0,
      tarif_contrat: 0,
      tarif_maladie: 0,
      tarif_avenant: 0,
      tarif_mission_excep: 0,
      tarif_stc: 0,
      entite_facturation: "",
      gestionnaire: null,
      societe_a_facturer: "Oui", // Default to Oui

    });
    setFormError("");
    setEditingCompany(null); // Assurez-vous qu'aucune société n'est en cours de modification
    fetchCompanies(); 
  };

  // Ajoutez cette fonction pour gérer la fermeture des modals
  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    setNewCompany({
      nom: "",
      adresse: "",
      ville: "",
      code_postal: "",
      email: "",
      // gestionnaire_nom: "",
      gestionnaire: null,
      societe_gestionnaire: "E2P",
      compte: "",
      compte_de_base: "412",
      montant_ftc: 0,

      tarif_unitaire_bulletin_unitaire: 0,
      tarif_acces_silae: 0,
      tarif_RC: 0,
      tarif_licenciement: 0,
      tarif_contrat: 0,
      tarif_maladie: 0,
      tarif_avenant: 0,
      tarif_mission_excep: 0,
      tarif_stc: null,
      entite_facturation: "",
      societe_a_facturer: "Oui",

    });
    setFormError("");
    setEditingCompany(null);
  };

  return (
    <div className="parent-layout">
      <BarreNavigation />
      <div className="content">
        <div>
          <Navbarhorizontal />
        </div>
        <div className="container">
          <h1>Gestion du portefeuille</h1>

          <Modal isOpen={isFacturationModalOpen} onClose={() => setIsFacturationModalOpen(false)} title="Sélectionnez le mois pour la facturation">
            <div>
              <select id="month-selection" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                {getPreviousMonths().map((month, index) => (
                  <option key={index} value={month}>{month}</option>
                ))}
              </select>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button onClick={() => handleMonthSelection(lastCreatedSiret)}>Confirmer</button>
              <button onClick={() => {
        setIsFacturationModalOpen(false);  // Ferme la modal
        toast.info("La génération de la facturation a été annulée.", {
          className: "myToastClassNameinfo",
          progressClassName: "myToastProgressClassName"
        });
      }}>Annuler la génération</button>
              </div>
            </div>
          </Modal>
          <div className="entete-portefeuille">
          <div className="search-container">
          <input
            type="text"
            placeholder="Rechercher par raison sociale ou SIRET..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          </div>
          <div className="btn-container-portefeuille">
          <button className="btn-ajout-portefeuille" onClick={openAddModal}>
            Créer une nouvelle société
          </button>
          </div>
          </div>
          <div className="table-container">
            <table className="companylist_table">
              <thead>
                <tr>
                  <th>Siret</th>
                  <th>Nom</th>
                  <th>Adresse</th>
                  <th>Ville</th>
                  <th>Code postal</th>
                  <th>Email</th>
                  <th>Gestionnaire</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {companies
    .filter((company) => {
      // Si aucun terme de recherche n'est entré, afficher toutes les sociétés
      if (!searchTerm) {
        return true;
      }
      // Sinon, afficher seulement les sociétés dont le nom contient le terme de recherche
      return company.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
              company.siret.includes(searchTerm);
    })
    .map((company) => (
                  <tr key={company.id}>
                    <td>{company.siret}</td>
                    <td>{company.nom}</td>
                    <td>{company.adresse}</td>
                    <td>{company.ville || "N/A"}</td>
                    <td>{company.code_postal || "N/A"}</td>
                    <td>{company.email || "N/A"}</td>
                    <td>{company.gestionnaire_nom || "N/A"}</td>

                    <td>
                      <div className="btn-container">
                        <button
                          className="edit-btn"
                          onClick={() => handleEditClick(company)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>

                        <button
                          className="delete-btn"
                          onClick={() => handleDelete(company.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
         
          <Modal
            isOpen={isModalOpen || isEditModalOpen}
            onClose={closeModal}
            title={modalTitle}
          >
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="form-field">
                {formError && <p style={{ color: "red" }}>{formError}</p>}
                <h4 className="title_tarif">Identification de la société</h4>
                <div className="btn-switch">
                <label className="switch" htmlFor="checkbox"> 
                  <input 
                  name="checkbox"
                  id="checkbox"
                  type="checkbox"
                  checked={newCompany.societe_a_facturer === "Oui"}
                  onChange={handleToggleChange} 

                  />
                  <span className="slider"></span>
                </label>

                  </div>
                <div className="flex">
                  <div className="input-group">
                    <label htmlFor="siret"> Siret </label>
                    <input
                      id="siret"
                      type="text"
                      name="siret"
                      value={newCompany.siret}
                      onChange={handleInputChange}
                      required
                      pattern="\d{14}"
                      title="Le SIRET doit contenir exactement 14 chiffres."
                      maxLength="14"
                      minLength="14"

                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="nom">Nom</label>
                    <input
                    id="nom"
                      type="text"
                      name="nom"
                      value={newCompany.nom}
                      onChange={handleInputChange}
                      required

                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="input-group">
                    <label htmlFor="adresse"> Adresse </label>
                    <input
                      id="adresse"
                      type="text"
                      name="adresse"
                      value={newCompany.adresse}
                      onChange={handleInputChange}
                      required

                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="ville">Ville</label>
                    <input
                      id="ville"
                      type="text"
                      name="ville"
                      value={newCompany.ville}
                      onChange={handleInputChange}
                      required
                     
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="input-group">
                    <label htmlFor="code postal"> Code postal </label>
                    <input
                      id="code postal"
                      type="text"
                      name="code_postal"
                      value={newCompany.code_postal}
                      onChange={handleInputChange}
                      pattern="\d{5}"
                      title="Le code postal doit contenir exactement 5 chiffres."
                      maxLength="5"
                      minLength="5"
                      required

                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="email"> Email </label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      value={newCompany.email}
                      onChange={handleInputChange}
                      required

                    />
                  </div>
                </div>
                <h4 className="title_tarif">Référence interne</h4>
                <div className="flex">
                  <div className="input-group">
                    <label htmlFor="gestionnaire_nom">Gestionnaire</label>
                    <select
                      id="gestionnaire_nom"
                      name="gestionnaire_nom"
                      value={newCompany.gestionnaire || ""}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Sélectionnez un gestionnaire</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input-group">
                    <label htmlFor="societe gestionnaire">
                      Societe-gestionnaire
                    </label>
                    <select
                      id="societe gestionnaire"
                      name="societe_gestionnaire"
                      value={newCompany.societe_gestionnaire}
                      onChange={handleInputChange}
                      className="societe_gestionnaire"
                      required
                    >
                      <option value="8P">8P</option>
                      <option value="E2P">E2P</option>
                    </select>
                </div>
                </div>
                <div className="flex">
                  <div className="input-group">
                    <label htmlFor="compte"> Compte </label>
                    <input
                      id="compte"
                      type="text"
                      name="compte"
                      value={newCompany.compte}
                      onChange={handleInputChange}
                      required
                     
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="compte_de_base"> Compte de base</label>
                  
                    <select
                      id="compte_de_base"
                      name="compte_de_base"
                      value={newCompany.compte_de_base}
                      onChange={handleInputChange}
                      className="societe_gestionnaire"
                      required
                    >
                      <option value="412">412</option>
                      <option value="411">411</option>
                    </select>
                  </div>
                </div>
                <div className="flex">
                  <div className="input-group">
                    <label htmlFor="entite_facturation">
                      Entité de facturation{" "}
                    </label>
                    <input
                      id="entite_facturation"
                      type="text"
                      name="entite_facturation"
                      value={newCompany.entite_facturation}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <h4 className="title_tarif">Tarification des prestations</h4>
                {/* <p>----------------------</p> */}
                <div className="montant">
                  <div className="flex">
                    <div className="input-group">
                      <label htmlFor="tarif_unitaire_bulletin_unitaire">
                        {" "}
                        Bulletin
                      </label>
                      <input
                        id="tarif_unitaire_bulletin_unitaire"
                        type="text"
                        name="tarif_unitaire_bulletin_unitaire"
                        value={newCompany.tarif_unitaire_bulletin_unitaire?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        required
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="tarif_teulade">Teulade</label>
                      <input
                        id="tarif_teulade"
                        type="text"
                        name="tarif_teulade"
                        value={newCompany.tarif_teulade?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le tarif teulade doit être au format chiffres"
                        required
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="tarif_RC">Rupture conventionnelle</label>
                      <input
                        id="tarif_RC"
                        type="text"
                        name="tarif_RC"
                        value={newCompany.tarif_RC?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le tarif RC doit être au format chiffres."
                        required
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="input-group">
                      <label htmlFor="tarif_licenciement">Licenciement</label>
                      <input
                        id="tarif_licenciement"
                        type="text"
                        name="tarif_licenciement"
                        value={newCompany.tarif_licenciement?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le tarif licenciement doit être au format chiffres"
                        required
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="tarif_contrat">Contrat</label>
                      <input
                        id="tarif_contrat"
                        type="text"
                        name="tarif_contrat"
                        value={newCompany.tarif_contrat?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le tarif contrat doit être au format chiffres"
                        required
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="tarif_maladie">Maladie</label>
                      <input
                        id="tarif_maladie"
                        type="text"
                        name="tarif_maladie"
                        value={newCompany.tarif_maladie?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le tarif maladie doit être au format chiffres,"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="input-group">
                      <label htmlFor="tarif_avenant">Avenant</label>
                      <input
                        id="tarif_avenant"
                        type="text"
                        name="tarif_avenant"
                        value={newCompany.tarif_avenant?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le tarif_avenant doit être au format chiffres"
                        required
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="tarif_mission_excep">Mission excep</label>
                      <input
                        id="tarif_mission_excep"
                        type="text"
                        name="tarif_mission_excep"
                        value={newCompany.tarif_mission_excep?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le tarif mission excep doit être au format chiffres."
                        required
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="tarif_stc">Solde tout compte</label>
                      <input
                        id="tarif_stc"
                        type="text"
                        name="tarif_stc"
                        value={newCompany.tarif_stc?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le tarif_stc doit être au format chiffres"
                        required
                      />
                    </div>
                    
                  </div>
                  <div className="flex">
                  <div className="input-group">
                      <label htmlFor="montant_ftc"> Montant FTC</label>
                      <input
                        id="montant_ftc"
                        type="text"
                        name="montant_ftc"
                        value={newCompany.montant_ftc?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le montant doit être au format chiffres"
                        required
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="tarif_acces_silae"> Accès SILAE</label>
                      <input
                        id="tarif_acces_silae"
                        type="text"
                        name="tarif_acces_silae"
                        value={newCompany.tarif_acces_silae?.toString().replace('.', ',') || ''}
                        onChange={handleTarifInputChange}
                        title="Le montant doit être au format chiffres"
                        required
                      />
                    </div>

                  </div>
                  <div className="form-actions">
                    <button onClick={closeModal} className="cancel-button">
                      Fermer
                    </button>
                    <button type="submit" className="submit-button">
                      Sauvegarder
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default CompanyList;
