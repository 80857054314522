import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import "./statistiqueEnvoiMail.css";

const StatistiqueEnvoiMail = ({ facturations, selectedMonth }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [totalEnvois, setTotalEnvois] = useState(0);

  useEffect(() => {
    const formatMonthYear = (date) => {
      const monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", 
                          "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
      const [year, month] = date.split("-");
      return `${monthNames[parseInt(month) - 1]}-${year}`;
    };
  
    const filteredFacturations = facturations.filter(fact => 
      fact.mois_facturation === formatMonthYear(selectedMonth)
    );
  
    const statsData = filteredFacturations.reduce((acc, fact) => {
      if (fact.date_envoi_mail) {
        acc["Envoyées"] = (acc["Envoyées"] || 0) + 1;
      } else {
        acc["Non envoyées"] = (acc["Non envoyées"] || 0) + 1;
      }
      return acc;
    }, { "Envoyées": 0, "Non envoyées": 0 });
  
    setFilteredData(Object.keys(statsData).map(key => ({
      name: key,
      value: statsData[key]
    })));
  
    // Calcul du total des facturations pour le mois sélectionné
    setTotalEnvois(filteredFacturations.length);
  }, [selectedMonth, facturations]);

  const COLORS = {
    "Envoyées": "#FCC712",       // Couleur pour les factures envoyées
    "Non envoyées": "#1D3B49"    // Couleur pour les factures non envoyées
  };

  return (
    <div className='container-statistique-envoimail'>
      <h2 className='title-statiquestatut'>Factures envoyées par mail</h2>
      <div style={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height={300}>
      <PieChart >
        <Pie
          data={filteredData}
          cx="50%"
          cy="60%"
          startAngle={180}
          endAngle={0}
          innerRadius={90}
          outerRadius={120}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
        >
          {filteredData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[entry.name]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend 
       className="my-custom-legend"
       layout="horizontal"
       horizOriginX="middle"
       style={{ marginLeft: '30px', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333'}}
        />
      </PieChart>
      </ResponsiveContainer>
      </div>
      <div className='total-facturations'>
        <h2>{totalEnvois}</h2>
      </div>
    </div>
  );
};

export default StatistiqueEnvoiMail;
