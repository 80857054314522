import React, { useEffect, useState } from "react";
import axios from "axios";
import { Badge } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import BarreNavigation from "../../components/BarreNavigation/BarreNavigation";
import Navbarhorizontal from "../../components/Navbarhorizontal/Navbarhorizontal";
import Modal from "../../components/Modal/Modal";
import API_BASE_URL from "../../API/ConfigAPI";
import "./facturation.css";

function Facturation() {
  const [facturations, setFacturations] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentFacturation, setCurrentFacturation] = useState({});
  const [formError, setFormError] = useState("");
  const [availableGestionnaires, setAvailableGestionnaires] = useState([]);
  const [searchRaisonSociale, setSearchRaisonSociale] = useState("");
// eslint-disable
  const [prevMonths, setPrevMonths] = useState([]);
  const [reduction, setReduction] = useState(0); // New state for reduction
  
  const [selectedMonth, setSelectedMonth] = useState(""); // Initialize selectedMonth state
  const [selectedStatut, setSelectedStatut] = useState(null); // New state for selected status


  useEffect(() => {
    // Exemple d'utilisation des variables
    console.log(prevMonths);
    console.log(reduction);
  }, [prevMonths, reduction]);

  const gestionnaireOptions = availableGestionnaires.map((nom) => ({
    label: nom,
    value: nom,
  }));

  const monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  const formatMonth = (date) => {
    return `${monthNames[date.getMonth()]}-${date.getFullYear()}`;
  };

  const monthToDate = (monthYear) => {
    const [monthName, year] = monthYear.split("-");
    const monthIndex = monthNames.indexOf(monthName.toLowerCase());
    return new Date(year, monthIndex);
  };

  const filterBySelectedMonth = (facturation) => {
    const facturationDate = monthToDate(facturation.mois_facturation);
    const selectedDate = monthToDate(selectedMonth);
    return (
      facturationDate.getMonth() === selectedDate.getMonth() &&
      facturationDate.getFullYear() === selectedDate.getFullYear()
    );
  };

  const facturationsBySelectedMonth = facturations.filter(
    filterBySelectedMonth
  );


  const filterBySelectedStatut = (facturation) => {
    return selectedStatut === null || facturation.statut === selectedStatut;
  };


  useEffect(() => {
    const today = new Date();
    const currentMonth = formatMonth(today);
    setActiveTabs([currentMonth]);

    const prevMonths = [];
    for (let i = 1; i <= 2; i++) {
      const prevMonth = new Date(today.getFullYear(), today.getMonth() - i, 1);
      prevMonths.push(formatMonth(prevMonth));
    }
    setPrevMonths(prevMonths);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeTabs, setActiveTabs] = useState([]);

  useEffect(() => {
    const uniqueMonths = new Set(
      facturations.map((fact) => fact.mois_facturation)
    );
    const uniqueMonthsArray = Array.from(uniqueMonths).sort(
      (a, b) => monthToDate(a) - monthToDate(b)
    );

    setActiveTabs(uniqueMonthsArray);

    // Assurez-vous que le dernier mois soit sélectionné par défaut
    if (uniqueMonthsArray.length > 0) {
      setSelectedMonth(uniqueMonthsArray[uniqueMonthsArray.length - 1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facturations]);

  const totalParStatutMoisSelectionne = facturationsBySelectedMonth.reduce(
    (acc, facturation) => {
      const { statut } = facturation;
      acc[statut] = (acc[statut] || 0) + 1;
      return acc;
    },
    {}
  );

  const handleSelectChange = (selectedOptions) => {
    const selectedNoms = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedGestionnaires(selectedNoms);
  };

  const handleRaisonSocialeChange = (e) => {
    setSearchRaisonSociale(e.target.value);
  };

  useEffect(() => {
    const gestionnaireNoms = facturations.map(
      (facturation) => facturation.gestionnaire_nom
    );
    const uniqueGestionnaireNoms = [...new Set(gestionnaireNoms)];
    setAvailableGestionnaires(uniqueGestionnaireNoms);
  }, [facturations]);

  const [selectedGestionnaires, setSelectedGestionnaires] = useState([]);

  useEffect(() => {
    fetchFacturations();
  }, []);

  const fetchFacturations = () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${API_BASE_URL}/home/api/facturations/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setFacturations(response.data);
      })
      .catch((error) =>
        console.error("Erreur lors de la récupération des données:", error)
      );
  };

  const openEditModal = (facturation) => {
    setCurrentFacturation(facturation);
    setReduction(facturation.pourcentage_reduction || 0); // Set initial reduction value

    setIsEditModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = value === "" ? "0" : value;
    setCurrentFacturation({
      ...currentFacturation,
      [name]: updatedValue,
    });
  };

  const handleNumericInput = (e) => {
    const { name, value } = e.target;

    if (value === "") {
      setCurrentFacturation({ ...currentFacturation, [name]: "" });
      return;
    }
    const regex = /^\d+(,\d{0,2})?$/;

    if (regex.test(value) || value === "") {
      setCurrentFacturation({
        ...currentFacturation,
        [name]: value.replace(",", "."),
      });
    }
  };
  const handleReductionChange = (e) => {
    let value = e.target.value;
  
    // Convert to number and handle leading zeros
    if (value !== "") {
      value = Number(value).toString(); // This will remove any leading zeros
    }
  
    // Ensure the value is within the bounds or empty
    if (value === "" || (value >= 0 && value <= 100)) {
      setCurrentFacturation({
        ...currentFacturation,
        pourcentage_reduction: value,
      });
    }
  };
  
  const handleRegularisationChange = (e) => {
    let value = e.target.value.replace(",", "."); // Remplacer la virgule par un point

    // Supprimer les zéros initiaux, sauf si la valeur est "0"
    if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
      value = value.slice(1);
    }

    // Assurez-vous que la valeur est un nombre positif ou vide
    if ((!isNaN(value) && parseFloat(value) >= 0) || value === "") {
      setCurrentFacturation({
        ...currentFacturation,
        regularisation: value,
      });
    }
  };

  const handleAvoirHTChange = (e) => {
    let value = e.target.value.replace(",", "."); // Remplacer la virgule par un point

    // Autoriser uniquement les chiffres, un point et un tiret pour les nombres négatifs
    if (/^-?\d*\.?\d*$/.test(value)) {
      setCurrentFacturation({
        ...currentFacturation,
        avoir_ht: value,
      });
    }
  };
  const handleCommentaireChange = (e) => {
    const { value } = e.target;
    setCurrentFacturation({
      ...currentFacturation,
      commentaire: value,
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    const preparedData = Object.entries(currentFacturation).reduce(
      (acc, [key, value]) => {
        acc[key] =
          key === "bulletin" ||
          key === "stc" ||
          key === "maladie" ||
          key === "RC" ||
          key === "contrat" ||
          key === "licenciement" ||
          key === "mission_excep" ||
          key === "avenant" ||
          key === "teulade" ||
          key === "acces_silae" ||
          key === "pourcentage_reduction" || // Add pourcentage_reduction to be processed
          key === "regularisation" ||
          key === "avoir_ht"
            ? value === ""
              ? 0
              : parseFloat(value)
            : value;
        return acc;
      },
      {}
    );
    preparedData.commentaire = currentFacturation.commentaire;

    //preparedData.pourcentage_reduction = parseFloat(reduction) || 0; // Use correct key for the backend
    preparedData.statut = "En revue";

    axios
      .put(
        `${API_BASE_URL}/home/api/facturations/${currentFacturation.id}/`,
        preparedData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setIsEditModalOpen(false);
        fetchFacturations();
        toast.success("La facturation a été mise à jour avec succès !");
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la mise à jour de la facturation:",
          error.response ? error.response.data : error
        );
        setFormError(
          "Une erreur s'est produite lors de la mise à jour des données."
        );
        toast.error("Erreur lors de la mise à jour de la facturation.");
      });
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
    setFormError("");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 500,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 500,
    }),
  };

  return (
    <div className="parent-layout">
      <BarreNavigation />
      <div className="content">
        <div>
          <Navbarhorizontal />
        </div>
        <div className="container">
          <h1>Déclaration des prestations</h1>

          <div className="banner-facturation">
            <div>
              <div className="search-container-facturation">
                <input
                  type="text"
                  placeholder="  Rechercher par raison sociale ou SIRET..."
                  value={searchRaisonSociale}
                  onChange={handleRaisonSocialeChange}
                  className="search-input-facturation"
                />
              </div>
              <div className="select-facturation">
                <Select
                  options={gestionnaireOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={handleSelectChange}
                  allowSelectAll={true}
                  value={gestionnaireOptions.filter((option) =>
                    selectedGestionnaires.includes(option.value)
                  )}
                  styles={customStyles}
                  placeholder="Filtrer les gestionnaires..."
                />
              </div>
            </div>
            <div className="statut-badges-container">
              <div className="statut-badges">
              <Badge
                  key="all"
                  bg={selectedStatut === null ? "dark" : "secondary"}
                  className={`me-2 ${selectedStatut === null ? "active" : ""}`}
                  onClick={() => setSelectedStatut(null)}
                  style={{
                    cursor: "pointer",
                    opacity: selectedStatut === null ? 1 : 0.6,
                  }}
                >
                  Tous
                </Badge>
                {Object.entries(totalParStatutMoisSelectionne).map(
                  ([statut, count]) => (
                    <Badge
                    key={statut}
                    bg={getBadgeVariant(statut)}
                    className={`me-2 ${
                      selectedStatut === statut ? "active" : ""
                    }`}
                    onClick={() =>
                      setSelectedStatut(
                        statut === selectedStatut ? null : statut
                      )
                    }
                    style={{
                      cursor: "pointer",
                      opacity: selectedStatut === statut ? 1 : 0.6,
                    }}
                  >
                    {`${statut} (${count})`}
                  </Badge>
                  )
                )}
              </div>
            </div>
          </div>
          {activeTabs.length > 0 && (
            <div className="tabsFacturation ">
              {activeTabs.map((tab) => (
                <div
                  key={tab}
                  className={`tab ${tab === selectedMonth ? "selected" : ""}`}
                  onClick={() => setSelectedMonth(tab)}
                >
                  {tab}
                </div>
              ))}
            </div>
          )}
          <div className="table-container">
            <table className="facturation_table">
              <thead>
                <tr>
                  <th>Raison Sociale</th>
                  <th>Gestionnaire</th>
                  <th>Bulletin</th>
                  <th>STC</th>
                  <th>Maladie</th>
                  <th>RC</th>
                  <th>Contrat</th>
                  <th>Licenciement</th>
                  <th>Mission excep</th>
                  <th>Avenant</th>
                  <th>Teulade</th>
                  <th>Accès SILAE</th>
                  <th>Statut</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {facturations
                  .filter((facturation) => {
                    const gestionnaireOk =
                      selectedGestionnaires.length === 0 ||
                      selectedGestionnaires.includes(
                        facturation.gestionnaire_nom
                      );

                    const raisonSocialeOk =
                      facturation.raison_sociale &&
                      typeof facturation.raison_sociale === "string" &&
                      facturation.raison_sociale
                        .toLowerCase()
                        .includes(searchRaisonSociale.toLowerCase());

                    const siretOk =
                      facturation.siret &&
                      typeof facturation.siret === "string" &&
                      facturation.siret.includes(searchRaisonSociale);

                    return gestionnaireOk && (raisonSocialeOk || siretOk);
                  })
                  .filter(filterBySelectedMonth)
                  .filter(filterBySelectedStatut) // Filter by selected status

                  .map((facturation) => (
                    <tr key={facturation.id}>
                      <td>
                        <div>{facturation.raison_sociale}</div>
                        <div>{facturation.siret}</div>
                      </td>
                      <td>{facturation.gestionnaire_nom}</td>
                      <td>{facturation.bulletin}</td>
                      <td>{facturation.stc}</td>
                      <td>{facturation.maladie}</td>
                      <td>{facturation.RC}</td>
                      <td>{facturation.contrat}</td>
                      <td>{facturation.licenciement}</td>
                      <td>{facturation.mission_excep}</td>
                      <td>{facturation.avenant}</td>
                      <td>{facturation.teulade}</td>
                      <td>{facturation.acces_silae}</td>
                      <td>
                        <Badge
                          bg={getBadgeVariant(facturation.statut)}
                          text="white"
                          className="custom-badge"
                        >
                          {facturation.statut}
                        </Badge>
                      </td>
                      <td>
                        <button
                          className="btn-declarer"
                          key={facturation.id}
                          onClick={() => openEditModal(facturation)}
                          disabled={facturation.statut === "Terminé"}
                          style={{
                            opacity: facturation.statut === "Terminé" ? 0 : 1,
                          }}
                        >
                          Déclarer
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeModal}
        title="Déclarer les prestations"
      >
        <div className="modal-body">
          <form onSubmit={handleSubmit} className="form-field"> 
            {formError && <div style={{ color: "red" }}>{formError}</div>}
            <h4 className="title_tarif">Identification de la société</h4>
            <div className="flex">
              <div className="input-group">
                <label htmlFor="siret"> Siret</label>
                <input
                  id="siret"
                  type="text"
                  name="siret"
                  value={currentFacturation.siret}
                  disabled
                />
              </div>
              <div className="input-group">
                <label htmlFor="raison_sociale"> Raison sociale </label>
                <input
                 id="raison_sociale"
                  type="text"
                  name="raison_sociale"
                  value={currentFacturation.raison_sociale}
                  disabled
                />
              </div>
            </div>
            <h4 className="title_tarif">Statut de la déclaration</h4>
            <div className="flex">
              <div className="input-group">
                <label htmlFor="mois_facturation"> Mois facturation</label>
                <input
                  id="mois_facturation"
                  type="text"
                  name="mois_facturation"
                  value={currentFacturation.mois_facturation}
                  onChange={handleInputChange}
                  disabled
                />
              </div>
              <div className="input-group">
                <label htmlFor="statut">statut</label>
                <input
                  id="statut"
                  type="text"
                  name="statut"
                  value={currentFacturation.statut}
                  onChange={handleInputChange}
                  title="Le statut doit être au format "
                  disabled
                />
              </div>
            </div>
            <h4 className="title_tarif">
              Déclaration des prestations réalisées
            </h4>
            <div className="flex">
              <div className="input-group">
                <label htmlFor="bulletin">Bulletin</label>
                <input
                  id="bulletin"
                  type="text"
                  name="bulletin"
                  value={
                    currentFacturation.bulletin?.toString().replace(".", ",") ||
                    ""
                  }
                  onChange={handleNumericInput}
                  title="Le montant doit être au format chiffres"
                />
              </div>
              <div className="input-group">
                <label htmlFor="stc">STC</label>
                <input
                  id="stc"
                  type="text"
                  name="stc"
                  value={
                    currentFacturation.stc?.toString().replace(".", ",") || ""
                  }
                  onChange={handleNumericInput}
                  title="Le stc doit être au format chiffres"
                />
              </div>
              <div className="input-group">
                <label htmlFor="maladie">Maladie</label>
                <input
                  id="maladie"
                  type="text"
                  name="maladie"
                  value={
                    currentFacturation.maladie?.toString().replace(".", ",") ||
                    ""
                  }
                  onChange={handleNumericInput}
                  title="Le Maladie doit être au format chiffres"
                />
              </div>
            </div>
            <div className="flex">
              <div className="input-group">
                <label htmlFor="RC">RC</label>
                <input
                  id="RC"
                  type="text"
                  name="RC"
                  value={
                    currentFacturation.RC?.toString().replace(".", ",") || ""
                  }
                  onChange={handleNumericInput}
                  title="Le RC doit être au format chiffres"
                />
              </div>
              <div className="input-group">
                <label htmlFor="contrat">Contrat</label>
                <input
                  id="contrat"
                  type="text"
                  name="contrat"
                  value={
                    currentFacturation.contrat?.toString().replace(".", ",") ||
                    ""
                  }
                  onChange={handleNumericInput}
                  title="Le contrat doit être au format chiffres"
                />
              </div>
              <div className="input-group">
                <label htmlFor="licenciement">licenciement</label>
                <input
                  id="licenciement"
                  type="text"
                  name="licenciement"
                  value={
                    currentFacturation.licenciement
                      ?.toString()
                      .replace(".", ",") || ""
                  }
                  onChange={handleNumericInput}
                  title="Le licenciement doit être au format chiffres"
                />
              </div>
            </div>
            <div className="flex">
              <div className="input-group">
                <label htmlFor="mission_excep">Mission excep</label>
                <input
                  id="mission_excep"
                  type="text"
                  name="mission_excep"
                  value={
                    currentFacturation.mission_excep
                      ?.toString()
                      .replace(".", ",") || ""
                  }
                  onChange={handleNumericInput}
                  title="Le mission_excep doit être au format chiffres"
                />
              </div>
              <div className="input-group">
                <label htmlFor="avenant">Avenant</label>
                <input
                  id="avenant"
                  type="text"
                  name="avenant"
                  value={
                    currentFacturation.avenant?.toString().replace(".", ",") ||
                    ""
                  }
                  onChange={handleNumericInput}
                  title="Le avenant doit être au format chiffres"
                />
              </div>
              <div className="input-group">
                <label htmlFor="teulade">teulade</label>
                <input
                  id="teulade"
                  type="text"
                  name="teulade"
                  value={
                    currentFacturation.teulade?.toString().replace(".", ",") ||
                    ""
                  }
                  onChange={handleNumericInput}
                  pattern="\d*"
                  title="Le teuladet doit être au format chiffres"
                />
              </div>
            </div>
            <div className="flex">
              <div className="input-group">
                <label htmlFor="acces_silae">acces_silae</label>
                <input
                  id="acces_silae"
                  type="text"
                  name="acces_silae"
                  value={
                    currentFacturation.acces_silae
                      ?.toString()
                      .replace(".", ",") || ""
                  }
                  onChange={handleNumericInput}
                  title="Le subvention doit être au format chiffres"
                />
              </div>
              <div className="input-group">
                <label htmlFor="pourcentage_reduction">Réduction (%)</label>
                <input
                  id="pourcentage_reduction"
                  type="number"
                  name="pourcentage_reduction"
                  value={currentFacturation.pourcentage_reduction || 0}
                  onChange={handleReductionChange}
                  min="0"
                  max="100"
                  title="La réduction doit être un pourcentage entre 0 et 100"
                />
              </div>
              <div className="input-group">
                <label htmlFor="regularisation">Régularisation (€)</label>
                <input
                  id="regularisation"
                  type="text" // Utiliser 'text' pour permettre l'entrée de virgules
                  name="regularisation"
                  value={
                    currentFacturation.regularisation !== undefined &&
                    currentFacturation.regularisation !== ""
                      ? currentFacturation.regularisation
                          .toString()
                          .replace(".", ",")
                      : ""
                  }
                  onChange={handleRegularisationChange}
                  onBlur={() => {
                    // Formater la valeur à deux décimales lorsque l'utilisateur quitte le champ
                    if (
                      currentFacturation.regularisation !== undefined &&
                      currentFacturation.regularisation !== ""
                    ) {
                      setCurrentFacturation({
                        ...currentFacturation,
                        regularisation: parseFloat(
                          currentFacturation.regularisation
                        ).toFixed(2),
                      });
                    }
                  }}
                  title="La régularisation doit être un nombre positif"
                />
              </div>
              
            </div>
            <div className="flex" >
            <div className="input-group">
                <label htmlFor="avoir_ht">Avoir HT (€)</label>
                <input
                 id="avoir_ht"
                  type="text" // Utiliser 'text' pour permettre l'entrée de virgules
                  name="avoir_ht"
                  value={
                    currentFacturation.avoir_ht !== undefined &&
                    currentFacturation.avoir_ht !== ""
                      ? currentFacturation.avoir_ht.toString().replace(".", ",")
                      : ""
                  }
                  onChange={handleAvoirHTChange}
                  onBlur={() => {
                    // Formater la valeur à deux décimales et s'assurer qu'elle est négative
                    if (
                      currentFacturation.avoir_ht !== undefined &&
                      currentFacturation.avoir_ht !== ""
                    ) {
                      const parsedValue = parseFloat(
                        currentFacturation.avoir_ht
                      );
                      setCurrentFacturation({
                        ...currentFacturation,
                        avoir_ht: isNaN(parsedValue)
                          ? ""
                          : (parsedValue <= 0
                              ? parsedValue
                              : -parsedValue
                            ).toFixed(2),
                      });
                    }
                  }}
                  title="L'avoir HT doit être un nombre négatif"
                />
              </div>
              
            </div>
            <div >
            <label htmlFor="commentaire" className="label-commentaire">  Commentaire à intégrer au mail :</label>
            <div>
            <textarea
              id="commentaire"
              className="textarea-commentaire"
              name="commentaire"
              value={currentFacturation.commentaire || ""}
              onChange={handleCommentaireChange}
              maxLength={200}
              placeholder="Vous pouvez entrer jusqu'à 200 caractères."
              title="Vous pouvez entrer jusqu'à 200 caractères."
            />
            </div>
  </div>
            <div className="form-actions">
              <button onClick={closeModal} className="cancel-button">
                Fermer
              </button>
              <button type="submit" className="submit-button">
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

function getBadgeVariant(statut) {
  switch (statut) {
    case "A revoir":
      return "warning";
    case "En revue":
      return "primary";
    case "Terminé":
      return "success";
    default:
      return "secondary";
  }
}

export default Facturation;
