import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer} from 'recharts';

const StatistiquePrestation = ({ facturations, selectedMonth }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const prestationsData = {
      Bulletin: 0,
      Teulade: 0,
      STC: 0,
      Contrat: 0,
      Licenciement: 0,
      Mission_excep: 0,
      Avenant: 0,
    };

    // Filtrer les facturations du mois sélectionné et accumuler les valeurs
    facturations.filter(fact => fact.mois_facturation === selectedMonth)
      .forEach(fact => {
        prestationsData.Bulletin += fact.bulletin;
        prestationsData.Teulade += fact.teulade;
        prestationsData.STC += fact.stc;
        prestationsData.Contrat += fact.contrat;
        prestationsData.Licenciement += fact.licenciement;
        prestationsData.Mission_excep += fact.mission_excep;
        prestationsData.Avenant += fact.avenant;
      });

    setData(Object.keys(prestationsData).map(key => ({
      name: key,
      value: prestationsData[key]
    })));
  }, [facturations, selectedMonth]);

  const COLORS = ['#FCC712','#6DB492', '#BEE4D0','#58B9D9', '#D9D9D9',  '#F36151', '#FBC8C5'];

  return (
    <div className='container-statistique-statut'>
           <h2 className='title-statiquestatut ' >déclaration des prestations</h2>
           <div style={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height={340}>
      <PieChart >
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={({percent }) => `${(percent * 100).toFixed(0)}%`}
          outerRadius={90}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
      </ResponsiveContainer>
      </div>
   
    </div>
  );
};

export default StatistiquePrestation;
